import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './website/login/login.component';
import { RegisterComponent } from './website/register/register.component';
import { ForgotPasswordComponent } from './website/forgot-password/forgot-password.component';
import { OtpComponent } from './website/otp/otp.component';
import { OtpLoginComponent } from './website/otp-login/otp-login.component';
import { AdminLoginComponent } from './website/login-admin/login.component';
import { SetPasswordComponent } from './website/set-password/set-password.component';
import { RestPasswordComponent } from './website/rest-password/rest-password.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { PrivacyPoliciesComponent } from './website/privacy-policies/privacy-policies.component';
import { UserManualComponent } from './website/user-manual/user-manual.component';
import { UserFaqsComponent } from './website/user-faqs/user-faqs.component';

const appRoutes: Routes = [

    { path: 'login', component: LoginComponent },
    { path: 'adminlogin', component: AdminLoginComponent },
    { path: 'register', component: RegisterComponent },
    // { path: 'admin-register', component: RegisterComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'verify-otp', component: OtpComponent },
    { path: 'set-password/:token', component: SetPasswordComponent },
    { path: 'reset-password/:Id', component: RestPasswordComponent },
    { path: 'adminlogin/otp-login', component: OtpLoginComponent },
    { path: 'otp-login', component: OtpLoginComponent },
    { path: 'payment/:status/:orderID', component: PaymentStatusComponent },
    { path: 'privacy-policy', component: PrivacyPoliciesComponent },
    { path: 'help', component: UserManualComponent },

];

export const routing = RouterModule.forRoot(appRoutes);

