import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MatchComparisonService {

    constructor(private http: HttpClient) { }

    getProfileData() {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('x-token-code', localStorage.getItem('token'));
        return this.http.get(`${environment.apiUrl}` + `users/profile`, { headers: headers })
        .pipe(map((response: any) => response));
    }
    
    payAndSubmit(data: any) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('x-token-code', localStorage.getItem('token'));
        return this.http.post(`${environment.apiUrl}` + `match-comparisons/add-new/`, data, { headers: headers })
            .pipe(map((res: Response) => res));
    }

    amount() {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('x-token-code', localStorage.getItem('token'));
        return this.http.get(`${environment.apiUrl}` + `masterdata/match-comparison`, { headers: headers })
            .pipe(map((res: any) => res));
    }

    saveOrder(data) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('x-token-code', localStorage.getItem('token'));
        return this.http.post(`${environment.apiUrl}` + `payments/save-order`, data, { headers: headers })
        .pipe(map((response: any) => response));
    }

    getAdmins() {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('x-token-code', localStorage.getItem('token'));
        return this.http.get(`${environment.apiUrl}` + `admins/list`, { headers: headers })
        .pipe(map((response: any) => response));
    }

    payInDollars(data) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('x-token-code', localStorage.getItem('token'));
        return this.http.post(`${environment.apiUrl}` + `match-comparisons/pay-pal/save-order`, data, { headers: headers })
        .pipe(map((response: any) => response));
    }
}
