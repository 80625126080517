import { Routes, RouterModule } from '@angular/router';

import { AppLayoutComponent } from './dashboards/layouts/app-layout/app-layout.component';

import { AppointmentsListComponent } from './dashboards/shared/appointments/appointments-list/appointments-list.component';
import { AppointmentsDetailsComponent } from './dashboards/shared/appointments/appointments-details/appointments-details.component';
import { SlotsAddComponent } from './dashboards/shared/slots/slots-add/slots-add.component';
import { SlotsDetailsComponent } from './dashboards/shared/slots/slots-details/slots-details.component';
import { PaymentsDetailsComponent } from './dashboards/shared/payments/payments-details/payments-details.component';
import { PaymentsListComponent } from './dashboards/shared/payments/payments-list/payments-list.component';
import { AuthorizedUsersListComponent } from './dashboards/shared/authorized-user/authorized-users-list/authorized-users-list.component';
import { AuthorizedUsersAddComponent } from './dashboards/shared/authorized-user/authorized-users-add/authorized-users-add.component';
import { JoinComponent } from './dashboards/shared/video-calls/join/join.component';
import { LiveComponent } from './dashboards/shared/video-calls/live/live.component';
import { AdminProfileComponent } from './dashboards/admin/admin-profile/admin-profile.component';
import { AdminHomeComponent } from './dashboards/admin/admin-home/admin-home.component';
import { NotificationsListComponent } from './dashboards/shared/notifications/notifications-list/notifications-list.component';
import { ClientsComponent } from './dashboards/shared/clients/clients.component';
import { ClientDetailsComponent } from './dashboards/shared/clients/client-details/client-details.component';
import { AuthGuardService } from './auth-guard.service';
import { UsersListComponent } from './dashboards/admin/users-list/users-list/users-list.component';
import { UserDetailsComponent } from './dashboards/admin/users-list/user-details/user-details.component';

const appRoutes: Routes = [
    {
        path: 'non-admin',
        component: AppLayoutComponent,
        children: [
            { path: '', component: AdminHomeComponent, canActivate: [AuthGuardService] },
            { path: 'appointments/list', component: AppointmentsListComponent, canActivate: [AuthGuardService] },
            { path: 'appointments/list/:type', component: AppointmentsListComponent, canActivate: [AuthGuardService] },
            { path: 'clients/list', component: ClientsComponent, canActivate: [AuthGuardService] },
            { path: 'appointments/details/:Id', component: AppointmentsDetailsComponent, canActivate: [AuthGuardService] },
            { path: 'client/details', component: ClientDetailsComponent, canActivate: [AuthGuardService] },
            { path: 'video/:appointment_id/:meeting_id', component: JoinComponent, canActivate: [AuthGuardService] },
            { path: 'video/:appointment_id/:meeting_id/live', component: LiveComponent, canActivate: [AuthGuardService] },
            { path: 'payments/list', component: PaymentsListComponent, canActivate: [AuthGuardService] },
            { path: 'authorized-users/list', component: AuthorizedUsersListComponent, canActivate: [AuthGuardService] },
            { path: 'authorized-users/add', component: AuthorizedUsersAddComponent, canActivate: [AuthGuardService] },
            { path: 'authorized-users/details/:non_admin_id', component: AuthorizedUsersAddComponent, canActivate: [AuthGuardService] },
            { path: 'slots/list', component: SlotsDetailsComponent, canActivate: [AuthGuardService] },
            { path: 'slots/add', component: SlotsAddComponent, canActivate: [AuthGuardService] },
            { path: 'slots/details', component: SlotsDetailsComponent, canActivate: [AuthGuardService] },
            { path: 'profile', component: AdminProfileComponent, canActivate: [AuthGuardService] },
            { path: 'notifications', component: NotificationsListComponent, canActivate: [AuthGuardService] },
            { path: 'payments/details', component: PaymentsDetailsComponent, canActivate: [AuthGuardService] },
            { path: 'users/list', component: UsersListComponent, canActivate: [AuthGuardService] },
            { path: 'user/details', component: UserDetailsComponent, canActivate: [AuthGuardService] },
        ]
    }
];

export const nonadminRoutes = RouterModule.forRoot(appRoutes);

