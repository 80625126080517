import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response, Http } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppointmentdetailsService {

  constructor(private http: HttpClient) { }

  getAppointmentDetails(data: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `appointments/` + data, { headers: headers })
    .pipe(map((response: any) => response));
  }

  alertUsers(data: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.post(`${environment.apiUrl}` + `appointments/alert-users`, data, { headers: headers })
    .pipe(map((response: any) => response));
  }
}
