import { Component, OnInit } from '@angular/core';
import { AdminhomeService } from './adminhome.service';
import { SlotsDetailsService } from '../../shared/slots/slots-details/slots-details.service';
import * as moment from 'moment';


@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent implements OnInit {
  responseMessage: any;
  userType: string;


  monthDays = {
    weeks: []
  };
  dailyData: any;
  daySlots = [];
  currentMonth: any;
  weekDays = {
    days: [],
    hours: []
  };
  weeklyData: any;
  monthlyData: any;
  currentWeek: any;
  currentDay: any;
  displayType = 'MONTHLY';
  displayDate: any;
  dailyDate = new Date();
  weekly: any;
  monthly: any;
  dateSelecter = this.dailyDate;
  message: any;
  requestMessage: any;
  loading: any;

  userData: any;

  constructor(private apicall: AdminhomeService, private slotsDetailsService: SlotsDetailsService) {
  }

  ngOnInit() {
    this.getDisplayDate(this.dailyDate, "");
    this.userType = localStorage.getItem('userType');
    this.userData = JSON.parse(localStorage.getItem('userData'));
    if (this.userType == 'admins') {
      this.userType = 'admin';
    }
    this.apicall.getwidgetcount().subscribe(response => {
      this.responseMessage = response;
      this.responseMessage = this.responseMessage.data;
    });
    this.getMonthly();
  }

  getDisplayDate(fromDate, toDate) {
    this.displayDate = moment(fromDate).format('YYYY-MM-DD') + ((toDate) ? (' to ' + moment(toDate).format('YYYY-MM-DD')) : '');
  }

  onChangeFilter() {
    this.dailyDate = new Date(this.dateSelecter);
    this.getMonthly();
  }


  getMonthly() {
    let days = [];
    this.requestMessage = 'Please wait... Fetching slots information...';
    new Promise((resolve, reject) => {
      resolve({
        monthNo: this.dailyDate.getMonth() + 1,
        yearNo: this.dailyDate.getFullYear(),
        startDate: new Date(moment(this.dailyDate).startOf('month').format()),
        endDate: new Date(moment(this.dailyDate).endOf('month').format())
      });
    })
      .then((response: any) => {
        this.monthly = response;
        this.loading = true;
        this.getDisplayDate(this.monthly.startDate, this.monthly.endDate);
        return new Promise((resolve, reject) => {
          this.slotsDetailsService.getByMonth(this.userData.admin_id, response.monthNo, response.yearNo)
            .subscribe(
              (res: any) => {
                this.loading = false;
                this.requestMessage = '';
                this.monthlyData = res.data;
                return resolve(this.monthlyData);
              });
        });
      })
      .then((response: any) => {
        this.monthDays = {
          weeks: []
        };
        let week = {
          days: []
        };
        let dayIndex = 0, date = 1;
        for (var i = 0; i < new Date(this.monthly.startDate).getDay(); i++) {
          week.days.push({
            day: '',
            date: ''
          });
          dayIndex++;
        }
        for (var i = 0; i < response.length; i++) {
          if (dayIndex == 7) {
            this.monthDays.weeks.push(week);
            week = {
              days: []
            };
          }
          dayIndex = dayIndex % 7;
          week.days.push(response[i]);
          dayIndex++;
        }

        if (week.days.length) {
          this.monthDays.weeks.push(week);
        }
      });
  }
}
