import { Component, OnInit } from '@angular/core';
import { permissions } from 'src/environments/constants';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  userType: any;
  activeTab = "dashboard";
  userData: any;
  routerUserType = "user";

  menuItems = [];

  constructor() {

  }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.userType = this.userData.user_type;
    if (this.userType == "non-admin" && this.userData.permissions) {
      this.routerUserType = "non-admin";
      let index = 0;
      this.userData.permissions.forEach((permission) => {
        let { add, view, update } = permission;
        if (add || view || update || permission.delete) {
          this.menuItems.push(permissions[index])
        };
        index++;
      })
    } else if (this.userType == "admin") {
      this.routerUserType = "admin";
      this.menuItems = permissions;
    } else if (this.userType == "user") {
      permissions.forEach((permission) => {
        if (permission.permission_id != 2 && permission.permission_id != 4 && permission.permission_id != 5 && permission.permission_id != 6)
          this.menuItems.push(permission);
      })
    }

    this.routeChecker();

  }

  routeChecker() {
    let url = window.location.href.split("/");
    if (url.length == 5) {
      let exists = false;
      this.menuItems.forEach((menu) => {
        if (menu.activeRoute == url[4])
          exists = true;
      })
      // if(!exists){
      //   window.location.href = "/"+this.userType;
      // }
    } else {
      this.activeTab = "dashboard";
    }
  }
}
