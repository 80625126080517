import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-card-info',
  templateUrl: './card-info.component.html',
  styleUrls: ['./card-info.component.css']
})
export class CardInfoComponent implements OnInit {

  constructor() { }
  ngOnInit() {
  }

  saveCardDetails() {
    const obj = {
    }
  }
 

}
