import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';

import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class SlotsAddService {

  constructor(private http: HttpClient) { }
  getByMonth() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `slots/list`, { headers: headers })
      .pipe(map((response: any) => response));
  }
  addUser(data) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.post(`${environment.apiUrl}` + `slots/add`, data, { headers: headers })
      .pipe(map((response: any) => response));
  }

  getCountryList() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `masterdata/countries`, { headers: headers })
      .pipe(map((response: any) => response));
  }
  getstateList(id: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `masterdata/states/` + id, { headers: headers })
      .pipe(map((response: any) => response));
  }

  getcityList(countryId: any, stateid: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `masterdata/cities/` + countryId + '/' + stateid, { headers: headers })
      .pipe(map((response: any) => response));
  }

  getDependents() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + 'dependents/list', { headers: headers })
      .pipe(map((response: any) => response));
  }

  addMany(dependents) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.post(`${environment.apiUrl}` + 'dependents/add-many', { dependents: dependents }, { headers: headers })
      .pipe(map((response: any) => response));
  }

  updateDependent(dependentID, data) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.put(`${environment.apiUrl}` + 'dependents/'+dependentID, data, { headers: headers })
      .pipe(map((response: any) => response));
  }

  getProfileData() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `users/profile`, { headers: headers })
    .pipe(map((response: any) => response));
  }

  getServices() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `masterdata/services`, { headers: headers })
    .pipe(map((response: any) => response));
  }

  getServiceCharges() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `masterdata/service-charges`, { headers: headers })
    .pipe(map((response: any) => response));
  }

  payAmount(data) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.post(`${environment.apiUrl}` + `payments/add`, data, { headers: headers })
    .pipe(map((response: any) => response));
  }

  addOrder(data) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.post(`${environment.apiUrl}` + `payments/order`, data, { headers: headers })
    .pipe(map((response: any) => response));
  }

  requestToGateway(data){
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.http.post(`https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction`, data, {headers: headers})
    .pipe(map((response: any) => response));
  }

  bookAppointments(data) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.post(`${environment.apiUrl}` + `appointments/add`, data, { headers: headers })
    .pipe(map((response: any) => response));
  }

  checkProfileValidity() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `users/profile-validity`, { headers: headers })
    .pipe(map((response: any) => response));
  }

  getAdmins() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `admins/list`, { headers: headers })
    .pipe(map((response: any) => response));
  }

  saveOrder(data) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.post(`${environment.apiUrl}` + `payments/save-order`, data, { headers: headers })
    .pipe(map((response: any) => response));
  }

}
