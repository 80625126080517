import { Component, OnInit } from '@angular/core';
import { PaymentlistService } from './paymentlist.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payments-list',
  templateUrl: './payments-list.component.html',
  styleUrls: ['./payments-list.component.css']
})
export class PaymentsListComponent implements OnInit {

  responseMessage: any;
  userType: any;
  currentPage: number;
  requestPage: number;
  selectedstatus = '';
  items = [];
  noData: boolean;
  loading = true;
  userData: any;
  filterDate: any;

  constructor(private router: Router, private apicall: PaymentlistService) { }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.userType = localStorage.getItem('userType');
    this.filterDate = '';

    // if (this.userData.profile == false) {
    //   this.router.navigate(['/user/profile']);
    // }
    if (this.userType == 'admins') {
      this.userType = 'admin';
    }
    if (this.userType == 'users') {
      this.userType = 'user';
    }

    this.listAll();
  }

  listAll() {
    this.apicall.getpaymentlist().subscribe(response => {
      this.responseMessage = response;
      this.items = this.responseMessage.data;
      this.loading = false;
    });
  }

  onChangeFilter(type) {
    this.noData = false;
    this.apicall.filterpayments(type).subscribe(response => {
      this.responseMessage = response;
      this.items = this.responseMessage.data;
      if (this.items.length == 0) {
        this.noData = true;
      }
    });
  }

  changePage(event) {
    this.currentPage = event;
    this.requestPage = this.currentPage - 1;
  }

  onChange() {
    this.noData = false;
    if (this.selectedstatus || this.filterDate) {
      this.apicall.filters(this.selectedstatus, this.filterDate).subscribe(response => {
        this.responseMessage = response;
        this.items = this.responseMessage.data;
        if (this.items.length == 0) {
          this.noData = true;
        }
      });
    } else {
      this.listAll();
    }
  }

  dateReset() {
    this.filterDate = '';
    this.onChange();
  }
}
