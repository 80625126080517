import { Component, OnInit } from '@angular/core';
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

import * as moment from 'moment';

import { days, hours, minutes } from 'src/environments/constants';
import { environment } from 'src/environments/environment';

import { SlotsDetailsService } from './slots-details.service';

declare var $: any;

@Component({
  selector: 'app-slots-details',
  templateUrl: './slots-details.component.html',
  styleUrls: ['./slots-details.component.css']
})
export class SlotsDetailsComponent implements OnInit {
  monthDays = {
    weeks: []
  };
  dailyData: any;
  daySlots = [];
  currentMonth: any;
  weekDays = {
    days: [],
    hours: []
  };
  weeklyData: any;
  monthlyData: any;
  currentWeek: any;
  currentDay: any;
  displayType = 'MONTHLY';
  displayDate: any;
  dailyDate: any;
  weekly: any;
  monthly: any;
  dateSelecter: any;
  message: any;
  requestMessage: any;
  loading: any;

  admin_id: any;

  userData: any;
  options: DatepickerOptions = {
    locale: enLocale
  };

  currentDate = moment(new Date()).format('YYYY-MM-DD');
  addButton = false;

  constructor(private slotsDetailsService: SlotsDetailsService) {

    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.admin_id = this.userData.admin_id;
    this.dailyDate = new Date();
    this.dailyDate = new Date(this.dailyDate.setHours(10));
    this.dateSelecter = this.dailyDate;
    if (this.displayType == "DAILY")
      this.getDaily();
    if (this.displayType == "MONTHLY")
      this.getMonthly();
    if (this.displayType == "WEEKLY")
      this.getWeekly();
  }

  ngOnInit() {
    this.getDisplayDate(this.dailyDate, "");
  }

  getDisplayDate(fromDate, toDate) {
    this.displayDate = moment(fromDate).format('YYYY-MM-DD') + ((toDate) ? (" to " + moment(toDate).format('YYYY-MM-DD')) : "");
  }

  getDaily() {
    this.addButton = false;
    this.message = "Please wait.. Fetching slots information...";
    this.loading = true;
    let date = moment(this.dailyDate).format('YYYY-MM-DD');
    this.daySlots = [];
    this.slotsDetailsService.getDailySlots(this.admin_id, date)
      .subscribe(
        (response) => {
          this.loading = false;
          this.dailyData = response.data;
          if (this.dailyData && this.dailyData.time_slots && this.dailyData.time_slots.length){
            this.daySlots = this.dailyData.time_slots;
          }
          else {
            let currentTime = new Date(this.currentDate), slotTime = new Date(date);
            if(currentTime.getTime() > slotTime.getTime()) {
              this.message = "You have no slots added for this date.";
            } else {
              this.message = "No slots are added for this date. You can add slots by clicking add button below..";
              this.addButton = true;
            }
          }
        });
  }

  addDailySlots() {
    this.slotsDetailsService.getSlotsList()
          .subscribe(
            (response) => {
              this.daySlots = response.data;
            })
    // this.daySlots.forEach((hour) => {
    //   hour.minutes = [];
    //   minutes.forEach((minute) => {
    //     hour.minutes.push({
    //       time_id: index,
    //       minute: minute.minute,
    //       meridiem: hour.meridiem,
    //       available: true,
    //       booked: false
    //     })
    //     index++;
    //   })
    // })
    // console.log(this.daySlots)
  }

  addSlots() {
    Swal.fire({
      title: 'Are you sure want confirm the slots?',
      type: 'info',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.requestMessage = "Please wait... We are updating your slots information..."
        let data = {
          admin_id: this.admin_id,
          date: this.dailyDate,
          time_slots: this.daySlots,
          week_no: parseInt(moment(this.dailyDate).format('w')),
          month: this.dailyDate.getMonth() + 1,
          year: this.dailyDate.getFullYear()
        }
        this.slotsDetailsService.addDaySlots(this.admin_id, data)
          .subscribe(
            (response) => {
              this.requestMessage = "";
              Swal.fire(
                'Saved!',
                'Your slots are added successfully',
                'success'
              );
            },
            error => {
              this.requestMessage = "";
            });
      }
    })
  }

  updateSlots() {
    Swal.fire({
      title: 'Are you sure want to update the slots?',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.requestMessage = "Please wait... We are updating your slots information..."
        let data = {
          time_slots: this.daySlots
        }
        this.slotsDetailsService.updateDaySlots(this.dailyData.slot_id, data)
          .subscribe(
            (response) => {
              this.requestMessage = "";
              Swal.fire(
                'Updated!',
                'Your slots are updated successfully',
                'success'
              );
            },
            error => {
              this.requestMessage = "";
            });
      }
    })
  }

  toggleAvailable(i, j) {
    if (!this.daySlots[i].minutes[j].booked && !this.daySlots[i].minutes[j].available) {
      this.daySlots[i].minutes[j].available = true;
    } else if (!this.daySlots[i].minutes[j].booked && this.daySlots[i].minutes[j].available) {
      this.daySlots[i].minutes[j].available = false;
    } else {
    }
  }

  getWeekly() {
    this.weekDays = {
      days: [],
      hours: []
    }
    this.loading = true;
    this.requestMessage = "Please wait... Fetching slots information...";
    new Promise((resolve, reject) => {
      resolve({
        weekNo: parseInt(moment(this.dailyDate).format('w')),
        yearNo: this.dailyDate.getFullYear(),
        startDate: new Date(moment(this.dailyDate).startOf('week').format('YYYY-MM-DD hh:mm:ss')),
        endDate: new Date(moment(this.dailyDate).endOf('week').format('YYYY-MM-DD hh:mm:ss'))
      })
    })
      .then((response: any) => {
        this.weekly = response;
        return this.slotsDetailsService.getByWeek(this.admin_id, response)
          .subscribe(
            (response) => {
              this.loading = false;
              this.requestMessage = "";
              this.weeklyData = response.data;
              this.weekDays.hours = this.weeklyData.hours;
              return this.weekDays;
            });
      })
      .then((response: any) => {
        this.getDisplayDate(this.weekly.startDate, this.weekly.endDate);
        return response;
      })
      .then((response) => {
        for (var d = this.weekly.startDate; d <= new Date(this.weekly.endDate.setHours(23)); d.setDate(d.getDate() + 1)) {
          let day = days[d.getDay()];
          this.weekDays.days.push({
            name: day,
            date: moment(new Date(d)).format('YYYY-MM-DD')
          })
        }
      })
  }

  getMonthly() {
    let days = [];
    this.requestMessage = "Please wait... Fetching slots information...";
    new Promise((resolve, reject) => {
      resolve({
        monthNo: this.dailyDate.getMonth() + 1,
        yearNo: this.dailyDate.getFullYear(),
        startDate: new Date(moment(this.dailyDate).startOf('month').format('YYYY-MM-DD hh:mm:ss')),
        endDate: new Date(moment(this.dailyDate).endOf('month').format('YYYY-MM-DD hh:mm:ss'))
      })
    })
      .then((response: any) => {
        this.monthly = response;
        this.loading = true;
        this.getDisplayDate(this.monthly.startDate, this.monthly.endDate);
        return new Promise((resolve, reject) => {
          this.slotsDetailsService.getByMonth(this.admin_id, response.monthNo, response.yearNo)
            .subscribe(
              (response) => {
                this.requestMessage = "";
                this.loading = false;
                this.monthlyData = response.data;
                return resolve(this.monthlyData);
              })
        });
      })
      .then((response: any) => {
        this.monthDays = {
          weeks: []
        }
        let week = {
          days: []
        };
        let dayIndex = 0, date = 1;
        for (var i = 0; i < new Date(this.monthly.startDate).getDay(); i++) {
          week.days.push({
            day: "",
            date: ""
          })
          dayIndex++;
        }
        for (var i = 0; i < response.length; i++) {
          if (dayIndex == 7) {
            this.monthDays.weeks.push(week);
            week = {
              days: []
            }
          }
          dayIndex = dayIndex % 7;
          week.days.push(response[i]);
          dayIndex++;
        }

        if (week.days.length) {
          this.monthDays.weeks.push(week);
        }
      })
  }

  onChangeFilter() {
    this.dailyDate = new Date(this.dateSelecter);
    if (this.displayType == "WEEKLY") {
      this.getWeekly();
    } else if (this.displayType == "MONTHLY") {
      this.getMonthly();
    } else {
      this.getDisplayDate(this.dailyDate, "");
      this.getDaily();
    }
  }

  onClickDay(day) {
    this.dailyDate = new Date(day.date);
    this.displayType = 'DAILY';
    this.getDisplayDate(this.dailyDate, "");
    this.getDaily();
  }

  showPopup() {
    $("#more-info-popup").modal("show");
  }

}

