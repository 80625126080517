import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient) { }

  userRegister(data: any) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.apiUrl}` + `users/register`, data)
      .pipe(map((res: Response) => res));
  }

  adminRegister(data: any) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.apiUrl}` + `admins/register`, data)
      .pipe(map((res: Response) => res));
  }
  getCountryList() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.http.get(`${environment.apiUrl}` + `masterdata/countries`, { headers: headers })
      .pipe(map((response: any) => response));
  }
}
