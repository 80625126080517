import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
  userType: any;
  horoscope_id: any;

  constructor() { }

  ngOnInit() {
    this.userType = localStorage.getItem('userType');
    this.horoscope_id = 25697413;
    if (this.userType == 'admins')
      this.userType = 'admin';
  }
}


