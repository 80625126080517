import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { UsersListService } from './users-list.service';
import { Router } from '@angular/router';

import { DatepickerOptions } from 'ng2-datepicker';

import Swal from 'sweetalert2/dist/sweetalert2.js'

declare var $: any;

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit {
  responseMessage: any;
  noData: boolean;
  loading = true;
  userData: any;
  userType: any;

  items = [];

  currentUser: any;

  document: any;
  other_document: any;

  constructor(private route: ActivatedRoute, private router: Router, private apicall: UsersListService) { }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.userType = localStorage.getItem('userType');
    if (this.userType == 'admins')
      this.userType = 'admin';

    this.loading = false;
    this.apicall.getUsersList().subscribe(response => {
      this.responseMessage = response;
      this.items = this.responseMessage.data;

      this.loading = false;
    });
  }

  onClickView(user) {
    this.currentUser = user;
    $("#user-popup").modal("show");
  }

  onClickDocs(user) {
    this.currentUser = user;
    console.log(user)
    this.document = "";
    (<HTMLInputElement>document.getElementById("file")).value = "";
    $("#documents-popup").modal("show");
  }

  readURL(event, contentIndex, optionIndex) {
    if (event && event.target && event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      let reader = new FileReader();
      reader.onload = e => {
        this.document = "" + reader.result;
      }

      reader.readAsDataURL(file);
    }
  }

  readOtherURL(event, contentIndex, optionIndex) {
    if (event && event.target && event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      let reader = new FileReader();
      reader.onload = e => {
        this.other_document = "" + reader.result;
      }

      reader.readAsDataURL(file);
    }
  }

  upload() {
    this.loading = true;
    let user_id = (this.currentUser.userType == "Dependent") ? this.currentUser.dependent_id : this.currentUser.user_id;
    this.apicall.uploadDoc(user_id, this.currentUser.userType, this.document, this.other_document).subscribe(response => {
      this.responseMessage = response;
      this.items = this.responseMessage.data;
      window.location.reload();
    });
  }


}
