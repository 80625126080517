import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OtpService } from './otp.service';
declare var $: any;

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {
  otpForm: FormGroup;
  submitted = false;
  showModal: boolean;
  responseMessage: any;
  errorMessage: any;
  buttonText = "Submit";


  constructor(private formBuilder: FormBuilder, private apicall: OtpService) { }

  ngOnInit() {
    this.otpForm = this.formBuilder.group({
      email_id: localStorage.getItem('emailAddress'),
      otp: ["", [Validators.required, Validators.minLength(5)]],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.otpForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.otpForm.invalid) {
      return;
    } else {
      this.errorMessage = '';
      this.buttonText = "Loading...";
      const userType = localStorage.getItem('userType');
      this.apicall.otpValidate(this.otpForm.value, userType).subscribe(response => {
        this.responseMessage = response;
        if (this.responseMessage.success == true) {
          $('#success-popup').modal({ backdrop: 'static', keyboard: false })
          $('#success-popup').modal('show');
        } else {
          this.errorMessage = this.responseMessage.message;
          this.buttonText = "Submit";
        }
      },
        error => {
          this.errorMessage = error.error.message;
          this.buttonText = "Submit";
        });
    }
  }
}
