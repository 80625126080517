import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentlistService {

  constructor(private http: HttpClient) { }

  getpaymentlist() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `payments/list`, { headers: headers })
      .pipe(map((res: Response) => res));
  }

  filterpayments(type: any, ) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `payments/list/` + type, { headers: headers })
      .pipe(map((res: Response) => res));
  }

  filters(status: any, date: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.post(`${environment.apiUrl}` + `payments/filters`, { status, date }, { headers: headers })
      .pipe(map((res: Response) => res));
  }
}
