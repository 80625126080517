import { Component, OnInit } from '@angular/core';

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { permissions } from 'src/environments/constants';
import { Router } from '@angular/router';
import { NavbarService } from './navbar.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  userType = "";
  menuItems = [];
  activeTab = "";
  userData: any;
  routerUserType = "user";
  responseMessage: any;
  constructor(private router: Router, private apicall: NavbarService) {
    let url = window.location.pathname.split("/");
    this.userType = url[1];
    this.activeTab = url[2];
  }

  ngOnInit() {

    this.apicall.getnotifcationCount().subscribe(response => {
      this.responseMessage = response;
      this.responseMessage = this.responseMessage.data;
    });
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.userType = this.userData.user_type;
    if (this.userType == "non-admin" && this.userData.permissions) {
      this.routerUserType = "non-admin";
      let index = 0;
      this.userData.permissions.forEach((permission) => {
        let { add, view, update } = permission;
        if (add || view || update || permission.delete) {
          this.menuItems.push(permissions[index])
        };
        index++;
      })
    } else if (this.userType == "admin") {
      this.routerUserType = "admin";
      this.menuItems = permissions;
    } else if (this.userType == "user") {
      permissions.forEach((permission) => {
        if (permission.permission_id != 2 && permission.permission_id != 4 && permission.permission_id != 5 && permission.permission_id != 6)
          this.menuItems.push(permission);
      })
    }

    
  }

  logout() {
    Swal.fire({
      title: '<strong>Are you sure want to Logout?</strong>',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonColor: '#d33',

    })
      .then((response) => {
        if (response.value) {
          this.userType = localStorage.getItem('userType');
          if (this.userType == 'admins') {
            this.router.navigateByUrl("/adminlogin");
          }
          else {
            this.router.navigateByUrl("/login");
          }

        } else {
          response.dismiss === Swal.DismissReason.cancel
        }
      })
  }

}
