import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { AppointmentlistService } from './appointments-list.service';
import { SlotsDetailsService } from '../../slots/slots-details/slots-details.service';
import { Router } from '@angular/router';

import { DatepickerOptions } from 'ng2-datepicker';

import Swal from 'sweetalert2/dist/sweetalert2.js'

declare var $: any;

@Component({
  selector: 'app-appointments-list',
  templateUrl: './appointments-list.component.html',
  styleUrls: ['./appointments-list.component.css']
})
export class AppointmentsListComponent implements OnInit {
  responseMessage: any;
  userType: any;
  currentPage: number;
  requestPage: number;
  selectedstatus = '';
  items = [];
  noData: boolean;
  loading = true;
  userData: any;
  currentAppointmentID: any;
  appointmentObj = {
    status: '',
    user_id: '',
    slot_id: '',
    time_id: '',
    comments: '',
    shiftSlot: {}
  };
  cancelStatus: any;
  current_date: any;
  filterDate: any;

  timings = [];
  slotsMessage: any;
  slotData: any;
  time: any;
  profileStatus = 'true';
  type = '';
  constructor(private route: ActivatedRoute, private router: Router, private apicall: AppointmentlistService, private SlotsDetailsService: SlotsDetailsService) {

  }
  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    // if (this.userData.profile == false) {
    //   this.router.navigate(['/user/profile']);
    // }
    this.current_date = new Date();
    this.userType = this.userData.user_type;
    this.route.params.subscribe(data => {
      this.type = data.type;
      if (!this.type)
        this.type = '';

      this.listAll();

    });
  }

  listAll() {
    this.apicall.getappointmentlist(this.type).subscribe(response => {
      this.responseMessage = response;
      this.items = this.responseMessage.data;

      this.loading = false;
    });
  }

  updateInvite(id: any, status, user_id, slot_id, time_id, appointment) {
    this.slotsMessage = "";
    $('#success-popup').modal({ backdrop: 'static', keyboard: false })
    $('#success-popup').modal('show');
    const obj = {
      status: status,
      user_id: user_id,
      slot_id: slot_id,
      time_id: time_id,
      comments: '',
      shiftSlot: {},
      user: {
        first_name: appointment.first_name,
        last_name: appointment.last_name,
        email_id: appointment.email_id,
        country_code: appointment.country_code,
        primary_contact_number: appointment.primary_contact_number
      },
      appt_date: appointment.appt_date,
      appt_time: appointment.appt_time
    };

    this.currentAppointmentID = id;
    this.appointmentObj = obj;
  }

  update(id: any, status, user_id, slot_id, time_id, admin_id, appointment) {
    const obj = {
      status: status,
      user_id: user_id,
      slot_id: slot_id,
      time_id: time_id,
      admin_id: admin_id,
      user: {
        first_name: appointment.first_name,
        last_name: appointment.last_name,
        email_id: appointment.email_id,
        country_code: appointment.country_code,
        primary_contact_number: appointment.primary_contact_number
      },
      appt_date: appointment.appt_date,
      appt_time: appointment.appt_time
    };

    this.apicall.updateInvite(id, obj).subscribe(response => {
      this.apicall.getappointmentlist(this.type).subscribe(response => {
        this.responseMessage = response;
        this.items = this.responseMessage.data;
      })
    });

  }

  submitAction() {
    new Promise((resolve, reject) => {
      if ((this.appointmentObj.status == "rejected") || (this.appointmentObj.status == "cancelled")) {
        if (this.cancelStatus == "shift") {
          if (this.time) {
            let time = this.time.split("#");
            this.appointmentObj.shiftSlot = {
              slot_id: this.slotData.slot_id,
              time_id: time[0],
              appt_date: moment(new Date(this.current_date)).format("YYYY-MM-DD"),
              appt_time: ((parseInt(time[1]) > 12) ? (parseInt(time[1]) - 12) : (parseInt(time[1]))) + ":" + (time[2]) + " " + time[3]
            }
            resolve();
          } else {
            this.slotsMessage = "Please select the time slot..";
            reject();
          }
        } else if (this.cancelStatus == "refund") {
          this.appointmentObj.status = "refund";
          resolve();
        } else {
          this.slotsMessage = "Please select the status";
          reject();
        }
      } else {
        resolve();
      }
    })
      .then((response) => {
        this.slotsMessage = "Please wait. Updating the slot information.."
        this.apicall.updateInvite(this.currentAppointmentID, this.appointmentObj).subscribe(response => {
          this.apicall.getappointmentlist(this.type).subscribe(response => {
            this.slotsMessage = "";
            $('#success-popup').modal('hide');
            this.responseMessage = response;
            this.items = this.responseMessage.data;
            this.appointmentObj = {
              status: '',
              user_id: '',
              slot_id: '',
              time_id: '',
              comments: '',
              shiftSlot: {}
            };
          });
        });
      })
      .catch((error) => {
        // console.log(error)
      })
  }

  onChange() {
    this.noData = false;
    if (this.selectedstatus || this.filterDate) {
      this.apicall.filter(this.selectedstatus, this.filterDate).subscribe(response => {
        this.responseMessage = response;
        this.items = this.responseMessage.data;
        if (this.items.length == 0) {
          this.noData = true;
        }
      });
    } else {
      this.listAll();
    }
  }

  dateReset() {
    this.filterDate = '';
    this.onChange();
  }

  changePage(event) {
    this.currentPage = event;
    this.requestPage = this.currentPage - 1;
  }

  getTime(date) {
    let year = new Date(date).getFullYear();
    let months = new Date(date).getMonth();
    let day = new Date(date).getDate();

    return new Date(year, months, day, 10, 0, 0)
  }

  getSlots() {
    if (this.cancelStatus == 'shift') {
      let timings = [];
      this.timings = [];
      this.slotsMessage = "Loading slots....";

      if (this.getTime(this.current_date).getTime() >= this.getTime(new Date()).getTime()) {

        this.SlotsDetailsService.getDailySlots(this.userData.admin_id, moment(this.current_date).format("YYYY-MM-DD")).subscribe(response => {
          this.slotData = response.data;
          if (response.data && response.data.time_slots) {
            this.slotsMessage = "";
            response.data.time_slots.forEach((time_slot) => {
              time_slot.minutes.forEach((minute) => {
                if (!minute.booked && minute.available && !minute.disable) {
                  timings.push({
                    time_id: minute.time_id,
                    hour: time_slot.hour,
                    minute: minute.minute,
                    meridiem: minute.meridiem
                    // meridiem: (time_slot.hour < 12) ? "AM" : "PM"
                  })
                }
              })
            })

            this.timings = timings;

          } else {
            this.slotsMessage = "No Slots are available on this day";
          }
        });

      }
      else {
        Swal.fire(
          'Oops!!',
          'No Slots are available on this day...',
          'error'
        );
      }

    } else {
      this.slotsMessage = "";
    }
  }
}
