import { Component, OnInit } from '@angular/core';
import { AuthorizedlistService } from './authorizedlist.service';
declare var $: any;

@Component({
  selector: 'app-authorized-users-list',
  templateUrl: './authorized-users-list.component.html',
  styleUrls: ['./authorized-users-list.component.css']
})
export class AuthorizedUsersListComponent implements OnInit {

  settings = {};
  responseMessage: any;
  userType: any;
  userIndex: any;
  myadminId: any;
  loading = true;
  currentPage: number;
  requestPage: number;

  
  constructor(private apicall: AuthorizedlistService) { }

  ngOnInit() {
    this.userType = localStorage.getItem('userType');
    if (this.userType == 'admins') {
      this.userType = 'admin';
    }
    this.apicall.getUserList().subscribe(response => {
      this.responseMessage = response;
      this.responseMessage = this.responseMessage.data;
      this.loading = false;
    });
  }

  deleteUser(adminId: any) {
    $('#success-popup').modal({ backdrop: 'static', keyboard: false })
    $('#success-popup').modal('show');
    this.myadminId = adminId;
  }

  confirmDelete() {
    console.log(this.myadminId);
    this.apicall.deleteUser(this.myadminId).subscribe(response => {
      $('#success-popup').modal({ backdrop: 'false', keyboard: false })
      $('#success-popup').modal('hide');
      this.apicall.getUserList().subscribe(response => {
        this.responseMessage = response;
        this.responseMessage = this.responseMessage.data;
      });
    });
  }
  changePage(event) {
    this.currentPage = event;
    this.requestPage = this.currentPage - 1;
  }
}
