import { Component, OnInit } from '@angular/core';
import { UserprofileService } from './userprofile.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
declare var $: any;
import { map } from 'rxjs/operators';
import { patternValidator } from 'src/app/patternValidator';
import * as enLocale from 'date-fns/locale/en';
import { DatepickerOptions } from 'ng2-datepicker';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  responseMessage: any;
  userType = "";
  errorMessage: any;
  profileUpdateForm: FormGroup;
  userData: any;
  edit = false;
  countrylist: any;
  stateList: any;
  selectedCountry = '';
  selectedState = '';
  selectedCity = '';
  countryid: any;
  stateid: any;
  citylist: any;
  submitted: boolean;
  selectedCode = '+91';
  selectedCodeAlt = '+91';
  dateselected = new Date();
  options: DatepickerOptions = {
    locale: enLocale,
    minYear: 1900,
    maxYear: this.dateselected.getFullYear() + 1
  };

  constructor(private router: Router, private formBuilder: FormBuilder, private apicall: UserprofileService) { }

  ngOnInit() {

    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.apicall.getUserData().subscribe(response => {
      this.responseMessage = response.data;
    },
      error => {
        this.errorMessage = error.error.message;
      });
    this.getFromData({});

    this.apicall.getCountryList().subscribe(data => {
      this.countrylist = data;
      this.countrylist = this.countrylist.data;
    });
    this.userType = localStorage.getItem('userType');
    if (this.userType == 'admins') {
      this.userType = 'admin';
    }
    if (this.userType == 'users') {
      this.userType = 'user';
    }
    if (this.responseMessage && this.responseMessage.date_of_birth) {
      this.dateselected = this.responseMessage.date_of_birth;
    } else {
      this.dateselected = new Date();
    }
  }

  getFromData(data) {
    this.profileUpdateForm = this.formBuilder.group({
      first_name: [data.first_name ? data.first_name : "", [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(/^[A-Za-z_-][A-Za-z0-9 _-]*$/)]],
      last_name: [data.last_name ? data.last_name : "", [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(/^[A-Za-z_-][A-Za-z0-9 _-]*$/)]],
      middle_name: [data.middle_name ? data.middle_name : "", []],
      horoscope_id: [data.horoscope_id ? data.horoscope_id : "", []],
      date_of_birth: [data.date_of_birth ? moment(new Date(data.date_of_birth)).toISOString().substr(0, 10) : "", [Validators.required, Validators.minLength(3)]],
      gender: [data.gender ? data.gender : "", [Validators.required, Validators.minLength(4)]],
      place_of_birth: [data.place_of_birth ? data.place_of_birth : "", [Validators.required, Validators.minLength(3)]],
      time_of_birth: [data.time_of_birth ? data.time_of_birth : "", [Validators.required, Validators.minLength(3)]],
      primary_contact_number: [data.primary_contact_number ? data.primary_contact_number : "", [Validators.maxLength(16), Validators.pattern(/^([0|\+[0-9]{1,5})?([1-9][0-9]{9})$/)]],
      alt_contact_number: [data.alt_contact_number ? data.alt_contact_number : "", [Validators.maxLength(16), Validators.pattern(/^([0|\+[0-9]{1,5})?([1-9][0-9]{9})$/)]],
      primary_address_1: [data.primary_address_1 ? data.primary_address_1 : "", [Validators.required, Validators.minLength(3)]],
      primary_address_2: [data.primary_address_2 ? data.primary_address_2 : "", [Validators.minLength(3)]],
      primary_city: [data.primary_city ? data.primary_city : "", [Validators.required, Validators.minLength(3)]],
      primary_state: [data.primary_state ? data.primary_state : "", [Validators.required, Validators.minLength(3)]],
      primary_zip: [data.primary_zip ? data.primary_zip : "", [Validators.required, Validators.minLength(3)]],
      primary_country: [data.primary_country ? data.primary_country.toUpperCase() : "", [Validators.required, Validators.minLength(3)]],
      reference_by: [data.reference_by ? data.reference_by : "", [Validators.minLength(3)]],
      reference_first_name: [data.reference_first_name ? data.reference_first_name : "", [Validators.minLength(3)]],
      reference_last_name: [data.reference_last_name ? data.reference_last_name : "", [Validators.minLength(3)]],
      reference_contact_number: [data.reference_contact_number ? data.reference_contact_number : "", [Validators.maxLength(16), Validators.pattern(/^([0|\+[0-9]{1,5})?([1-9][0-9]{9})$/)]],
      reference_email: [data.reference_email ? data.reference_email : "", [patternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      profilepic: [data.profilepic ? data.profilepic : "", [Validators.minLength(3)]],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.profileUpdateForm.controls; }

  onCountryChange(event, loadCities) {
    if (this.countrylist) {
      let index = this.countrylist.map(function (country) { return "" + country.countryname; }).indexOf("" + this.profileUpdateForm.value.primary_country);
      if (index >= 0) {
        this.countryid = this.countrylist[index].countryid;
        this.citylist = null;
        this.stateList = null;
      }
      this.apicall.getstateList(this.countryid).subscribe(data => {
        this.stateList = data;
        this.stateList = this.stateList.data;
        if (loadCities) {
          this.onStateChange(this.responseMessage)
        }
      });
    }
  }
  onStateChange(event) {
    let index = this.stateList.map(function (state) { return "" + state.statename; }).indexOf("" + this.profileUpdateForm.value.primary_state);
    if (index >= 0) {
      this.stateid = this.stateList[index].stateid;
      this.apicall.getcityList(this.countryid, this.stateid).subscribe(data => {
        this.citylist = data;
        this.citylist = this.citylist.data;
      });
    }
  }
  saveUser() {
    this.submitted = true;
    console.log(this.profileUpdateForm)
    if (this.profileUpdateForm.invalid) {
      return;
    }

    console.log(this.profileUpdateForm)
    this.apicall.updateUserData(this.profileUpdateForm.value).subscribe(response => {
      // this.responseMessage = response.data;
      if (response.success === true) {
        this.userData.profile = true;
        this.userData.first_name = this.profileUpdateForm.value.first_name;
        this.userData.last_name = this.profileUpdateForm.value.last_name;
        this.userData.horoscope_id = this.profileUpdateForm.value.horoscope_id;
        localStorage.setItem('userData', JSON.stringify(this.userData));

      }
      //  this.userData.profile = true;
      $('#success-popup').modal({ backdrop: 'static', keyboard: false })
      $('#success-popup').modal('show');

      // this.router.navigate(['/user']);
    },
      error => {
        this.errorMessage = error.error.message;
      });

  }

  reload() {
    $('#success-popup').modal('hide');
    location.reload(true);
  }
  editAccount() {
    this.edit = true;
    this.getFromData(this.responseMessage);
    if (this.responseMessage.primary_country) {
      this.onCountryChange(this.responseMessage, true);
    }
    if (this.responseMessage.date_of_birth) {
      this.dateselected = this.responseMessage.date_of_birth;
    } else {
      this.dateselected = new Date();
    }
  }
}
