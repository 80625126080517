import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ClientsService {

    constructor(private http: HttpClient) { }

    getClients() {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('x-token-code', localStorage.getItem('token'));
        return this.http.get(`${environment.apiUrl}` + `appointments/active-clients`, { headers: headers })
        .pipe(map((response: any) => response));
    }
}
