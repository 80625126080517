import { Component, OnInit } from '@angular/core';
import { UserhomeService } from './userhome.service';
import { SlotsDetailsService } from '../../shared/slots/slots-details/slots-details.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.css']
})
export class UserHomeComponent implements OnInit {
  responseMessage: any;
  userType: string;

  monthDays = {
    weeks: []
  };
  dailyData: any;
  daySlots = [];
  currentMonth: any;
  weekDays = {
    days: [],
    hours: []
  };
  weeklyData: any;
  monthlyData: any;
  currentWeek: any;
  currentDay: any;
  displayType = 'MONTHLY';
  displayDate: any;
  dailyDate: any;
  weekly: any;
  monthly: any;
  dateSelecter: any;
  message: any;
  requestMessage: any;
  loading: any;
  userData: any;
  profileStatus = 'true';
  constructor(private router: Router, private apicall: UserhomeService, private slotsDetailsService: SlotsDetailsService) {
    this.dailyDate = new Date();
    this.dateSelecter = this.dailyDate;
  }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    console.log(this.userData)
    // if (this.userData.profile == false) {
    //   this.router.navigate(['/user/profile']);
    // }
    this.getDisplayDate(this.dailyDate, "");
    this.userType = localStorage.getItem('userType')
    if (this.userType == 'users') {
      this.userType = 'user';
    }

    this.apicall.getwidgetcount().subscribe(response => {
      this.responseMessage = response;
      this.responseMessage = this.responseMessage.data;
    });
    this.getMonthly();

  }
  getDisplayDate(fromDate, toDate) {
    this.displayDate = moment(fromDate).format('YYYY-MM-DD') + ((toDate) ? (" to " + moment(toDate).format('YYYY-MM-DD')) : "");
  }

  onChangeFilter() {
    this.dailyDate = new Date(this.dateSelecter);
    this.getMonthly();
  }


  getMonthly() {
    let days = [];
    this.requestMessage = "Please wait... Fetching slots information...";
    new Promise((resolve, reject) => {
      resolve({
        monthNo: this.dailyDate.getMonth() + 1,
        yearNo: this.dailyDate.getFullYear(),
        startDate: new Date(moment(this.dailyDate).startOf('month').format('YYYY-MM-DD hh:mm:ss')),
        endDate: new Date(moment(this.dailyDate).endOf('month').format('YYYY-MM-DD hh:mm:ss'))
      })
    })
      .then((response: any) => {
        this.monthly = response;
        this.loading = true;
        this.getDisplayDate(this.monthly.startDate, this.monthly.endDate);
        return new Promise((resolve, reject) => {
          this.slotsDetailsService.getByMonth(this.userData.user_id, response.monthNo, response.yearNo)
            .subscribe(
              (response) => {
                this.loading = false;
                this.requestMessage = "";
                this.monthlyData = response.data;
                return resolve(this.monthlyData);
              })
        });
      })
      .then((response: any) => {
        this.monthDays = {
          weeks: []
        }
        let week = {
          days: []
        };
        let dayIndex = 0, date = 1;
        for (var i = 0; i < new Date(this.monthly.startDate).getDay(); i++) {
          week.days.push({
            day: "",
            date: ""
          })
          dayIndex++;
        }
        for (var i = 0; i < response.length; i++) {
          if (dayIndex == 7) {
            this.monthDays.weeks.push(week);
            week = {
              days: []
            }
          }
          dayIndex = dayIndex % 7;
          week.days.push(response[i]);
          dayIndex++;
        }

        if (week.days.length) {
          this.monthDays.weeks.push(week);
        }
      })
  }
}

