import { Component, OnInit } from '@angular/core';

import { ClientsService } from './clients.services';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  responseMessage: any;
  userType: any;
  currentPage: number;
  requestPage: number;
  selectedstatus = '';
  items = [];
  noData: boolean;
  loading = true;

  constructor(private apicall: ClientsService) {

  }

  ngOnInit() {
    this.apicall.getClients().subscribe(response => {
      this.responseMessage = response;
      this.items = this.responseMessage.data;
      this.loading = false;

    });
  }
}