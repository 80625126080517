import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminserviceService {

  constructor(private http: HttpClient) { }

  getAdminData() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `admins/profile`, { headers: headers })
      .pipe(map((response: any) => response));
  }
  getCountryList() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `masterdata/countries`, { headers: headers })
      .pipe(map((response: any) => response));
  }
  getstateList(id: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `masterdata/states/` + id, { headers: headers })
      .pipe(map((response: any) => response));
  }

  getcityList(countryId: any, stateid: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `masterdata/cities/` + countryId + '/' + stateid, { headers: headers })
      .pipe(map((response: any) => response));
  }

  updateUserData(data) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.put(`${environment.apiUrl}` + `admins/profile`, data, { headers: headers })
      .pipe(map((response: any) => response));
  }
}
