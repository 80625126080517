import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';


import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

import { SlotsDetailsService } from '../slots-details/slots-details.service';

import { SlotsAddService } from './slots-add.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { resolve, reject } from 'q';

declare var $: any;

@Component({
  selector: 'app-slots-add',
  templateUrl: './slots-add.component.html',
  styleUrls: ['./slots-add.component.css']
})
export class SlotsAddComponent implements OnInit {
  grid = true;
  userType: any;
  userData: any;
  timings = [];
  a: any;
  currentDay: any;
  currentWeek: any;
  currentMonth: any;
  currentYear: any;
  dt: any;
  selected = [];
  selectedSlots = [];
  member_type = 'primary';
  members = [];
  priority = '';
  comments: any;
  dependents_message: any;
  profile = {
    name: '',
    mobile: '',
    first_name: '',
    last_name: '',
    country_code: '',
    primary_contact_number: ''
  };
  date_picker: any;
  current_date: Date;
  dateselected = new Date();
  options: DatepickerOptions = {
    locale: enLocale,
    minYear: 1900,
    maxYear: this.dateselected.getFullYear() + 1
  };
  services = [];
  service_charges = [];
  totalCost = 0;
  slotData: any;
  slotsMessage = "";
  paymentMessage: any;
  message: any;
  prev_date: any;
  payment_type = 'inr';

  profileIncomplete = false;

  admins = [];

  selected_admin_id = '';

  encRequest = "";
  accessCode = "";

  apiUrl: any;

  merchant_id: any;
  order_id: any;

  websiteUrl: any;

  horoscopeid = false;
  horoscope_id = "";


  constructor(private router: Router, private SlotsDetailsService: SlotsDetailsService, private slotsAddService: SlotsAddService) {
    var myDate = new Date(2013, 3, 25)
    let date = new Date();
    this.currentDay = date.getDay();
    this.currentWeek = this.getWeek(date);
    this.currentMonth = date.getMonth() + 1;
    this.currentYear = date.getFullYear();
    this.getAdmins();
    this.current_date = this.getTime(new Date());
    this.prev_date = this.current_date;
    this.apiUrl = environment.apiUrl;
    this.websiteUrl = environment.websiteUrl;
  }

  getAdmins() {
    this.slotsAddService.getAdmins().subscribe(response => {
      if (response.data && response.data) {
        this.admins = response.data;
      }
    });
  }

  getWeek(date) {
    this.dt = new Date(date.getFullYear(), 0, 1);
    return Math.ceil((((date - this.dt) / 86400000) + this.dt.getDay() + 1) / 7);
  };

  ngOnInit(): void {
    this.accessCode = 'USEWXH60D78FM8KF';
    this.userData = JSON.parse(localStorage.getItem('userData'));
    // if (this.userData.profile == false) {
    //   this.router.navigate(['/user/profile']);
    // }

    this.accessCode = 'AVBZ02BJ81BA06ZBAB';

    this.userType = this.userData.user_type;

    this.current_date = this.getTime(new Date());

    this.slotsAddService.getServices().subscribe(response => {
      if (response.data && response.data) {
        this.services = response.data;
      }
    });

    this.slotsAddService.getServiceCharges().subscribe(response => {
      if (response.data && response.data) {
        this.service_charges = response.data;
      }
    });

    this.slotsAddService.getProfileData().subscribe(response => {
      this.profile = response.data;
      this.profile.name = this.profile.first_name + " " + this.profile.last_name;
      this.profile.mobile = this.profile.country_code + "" + this.profile.primary_contact_number;

    });

    this.addMember();
    this.getDependentsList();
  }

  set() {

    if (this.selectedSlots.length) {
      this.paymentMessage = "";
      this.dependents_message = "";
      this.message = "";
      this.comments = "";
      this.dependents_message = "";
      $("#bookings-popup").modal("show");
    } else {
      Swal.fire({
        title: '<strong>No Slots Selected</strong>',
        type: 'warning',
        html: 'You haven\'t selected any slots. Please select and proceed..'
      })
    }
  }

  getTime(date) {
    let year = new Date(date).getFullYear();
    let months = new Date(date).getMonth();
    let day = new Date(date).getDate();

    return new Date(year, months, day, 10, 0, 0)
  }

  dateChange() {
    if (this.selected_admin_id) {
      if (this.getTime(this.current_date).getTime() >= this.getTime(new Date()).getTime()) {
        this.getDateSlots(this.getTime(this.current_date));
        this.prev_date = this.current_date;
      } else {
        this.current_date = this.getTime(this.prev_date);
        Swal.fire(
          'Oops!!',
          'You can\'t see these slots...',
          'error'
        );
      }
    } else {
      this.slotsMessage = "Please select the admin and search..";
    }
  }

  getDateSlots(date) {
    this.timings = [];
    this.selectedSlots = [];
    this.slotsMessage = "Loading slots....";
    this.SlotsDetailsService.getDailySlots(this.selected_admin_id, moment(date).format("YYYY-MM-DD")).subscribe(response => {
      this.slotData = response.data;
      if (response.data && response.data.time_slots) {
        this.timings = response.data.time_slots;
      } else {
        this.slotsMessage = "No Slots are available on this day";
      }
    });
  }

  onSelectSlot(i, j, available, disabled, status, a) {
    if (available && !disabled) {
      if (this.timings[i].minutes[j].status && (this.timings[i].minutes[j].user_id == this.userData.user_id)) {
        Swal.fire({
          title: '<strong>This slot is booked by yourself.</strong>',
          type: 'info',
          html: 'Current status is: ' + this.timings[i].minutes[j].status
        })
      } else if (!status || (this.timings[i].minutes[j].user_id == this.userData.user_id)) {
        let index = this.selectedSlots.map(function (selectedSlot) { return "" + selectedSlot.time_id; }).indexOf("" + this.timings[i].minutes[j].time_id);
        if (index >= 0) {
          this.timings[i].minutes[j].booked = false;
          this.timings[i].minutes[j].user_id = undefined;
          this.selectedSlots.splice(index, 1);
        } else {
          this.timings[i].minutes[j].booked = true;
          this.timings[i].minutes[j].user_id = this.userData.user_id;
          let data = this.timings[i].minutes[j];
          data.hour = this.timings[i].hour;
          data.service_id = "";
          data.user_id = this.userData.user_id;
          data.member_type = "";
          data.dependent_id = "";
          data.name = "";
          data.cost = 0;
          this.selectedSlots.push(data);
        }
      } else {
        Swal.fire({
          title: '<strong>This slot is not available.</strong>',
          type: 'info',
          html: 'You can\'t book this slot. It\'s already booked.'
        })
      }
    } else {
      Swal.fire({
        title: '<strong>This slot is not available.</strong>',
        type: 'info',
        html: 'You can\'t book this slot.'
      })
    }
  }
  checkEmpty() {
    let exists = false;
    this.members.forEach((member) => {
      // member.dependent_dob = moment(new Date(member.dependent_dob)).toISOString().substr(0, 10);
      if (!member.dependent_first_name || !member.dependent_last_name || !member.dependent_dob || !member.dependent_place_of_birth || !member.dependent_time_of_birth)
        exists = true;
    })
    return exists;
  }
  addMember() {
    if (!this.checkEmpty()) {
      this.members.push({
        dependent_first_name: "",
        dependent_last_name: "",
        dependent_middle_name: "",
        dependent_dob: this.dateselected,
        dependent_place_of_birth: "",
        dependent_time_of_birth: "",
        is_deleted: false,
        dependent_horoscope_id: ""
      })
    } else {
      this.dependents_message = "Some fields are missing"
    }
  }
  saveMembers() {
    if (!this.checkEmpty()) {
      this.message = "Please wait.. We are updating the dependents information..";
      this.members.forEach((member) => {
        member.dependent_dob = moment(this.getTime(member.dependent_dob)).toISOString().substr(0, 10);
      });
      this.slotsAddService.addMany(this.members).subscribe(response => {
        this.message = "";
        this.dependents_message = "";
        $('#success-popup').modal({ backdrop: 'static', keyboard: false })
        $('#success-popup').modal('show');
      });
    } else {
      this.dependents_message = "All fields are Mandatory!"
    }
  }
  getDependentsList() {
    this.slotsAddService.getDependents().subscribe(response => {
      if (response.data && response.data) {
        this.members = response.data;
        console.log(this.members)
        this.members.forEach((member) => {
          member.dependent_dob = moment(new Date(member.dependent_dob)).toISOString().substr(0, 10);

        })
      }
    });
  }
  onChangeMember(data, index) {
    let selected_types = data.split("#");
    let type = selected_types[0];

    if (type == "self") {
      this.selectedSlots[index].name = this.userData.first_name + " " + this.userData.last_name;
      this.selectedSlots[index].user_id = this.userData.user_id;
      this.selectedSlots[index].dependent_id = "";
      this.selectedSlots[index].horoscope_id = this.userData.horoscope_id;
    } else {
      this.selectedSlots[index].name = this.members[selected_types[2]].dependent_first_name + " " + this.members[selected_types[2]].dependent_last_name;
      this.selectedSlots[index].dependent_id = this.members[selected_types[2]].dependent_id || "";
      this.selectedSlots[index].user_id = "";
      this.selectedSlots[index].horoscope_id = this.members[selected_types[2]].dependent_horoscope_id || "";
    }

    if (this.selectedSlots[index].service_id)
      this.onChangeService(this.selectedSlots[index].service_id, index);
  }
  onChangeService(id, index) {
    let cost = 0;
    let type = (this.selectedSlots[index].user_id) ? "New User" : "Existing User";
    let service_type = "";
    this.service_charges.forEach((charge) => {
      if (charge.user_type == type && charge.service_type_id == id) {
        cost = parseInt(charge.service_charge_amount);
      }
    });

    let i = this.services.map(function (service) { return "" + service.service_type_id; }).indexOf("" + id);

    this.selectedSlots[index].cost = cost;
    this.selectedSlots[index].service_type = (i >= 0) ? this.services[i].service_type : "";
    this.selectedSlots[index].service_description = (i >= 0) ? this.services[i].service_description : "";
    this.totalCost = 0;

    this.selectedSlots.forEach((selectedSlot) => {
      this.totalCost += selectedSlot.cost;
    })

  }
  checkSlotsValidity(): any {
    let invalidExists = false;
    this.selectedSlots.forEach((slot) => {
      if ((!slot.user_id && !slot.dependent_id) || !slot.service_id || ((slot.service_id == "4") && !slot.horoscope_id)) {
        invalidExists = true;
      }
      if ((slot.service_id == "4") && !slot.horoscope_id) {
        slot.haserror = true;
      }
    });
    return invalidExists;
  }
  confirmPayment(type) {
    this.paymentMessage = "";
    if (!this.checkSlotsValidity()) {

      if (this.totalCost) {
        this.profileIncomplete = false;
        this.paymentMessage = "Please wait.. You will be redirected to the payment page..";

        let payment = {
          "currency": "inr",
          "status": "success",
          "amount": this.totalCost,
          "admin_id": this.selected_admin_id,
          "account_id": null,
          "user_id": this.userData.user_id,
          "card_id": null,
          "trancation_id": null,
          "slotsCount": this.selectedSlots.length
        };

        let data = {
          "no_of_appointments": this.selectedSlots.length,
          "payment_status": "inprogress",
          "payment_id": "1",
          "admin_id": this.selected_admin_id,
          "user_id": this.userData.user_id,
          "timezone": "ist",
          "comments": this.comments,
          "priority": this.priority,
          "amount": this.totalCost,
          "slots": [],
          "email_id": this.userData.email_id,
          "first_name": this.userData.first_name,
          "last_name": this.userData.last_name,
          "payment": payment,
          "horoscope_id": this.horoscope_id,
          "slot_id": this.slotData.slot_id,
          "time_id": "",
          "order_for": "appointments",
          "paying_through": ((type == 'inr') ? 'ccavenue' : 'paypal')
        };

        this.selectedSlots.forEach((slot) => {
          data.slots.push({
            "appt_type": (slot.user_id) ? "self" : "dependent",
            "appt_service_type": slot.service_id,
            "service_type_name": slot.service_description,
            "appt_date": moment(new Date(this.current_date)).format("YYYY-MM-DD"),
            "appt_time": ((slot.hour > 12) ? slot.hour - 12 : slot.hour) + ":" + slot.minute + " " + slot.meridiem,
            "slot_id": this.slotData.slot_id,
            "time_id": slot.time_id,
            "user_id": slot.user_id,
            "amount_paid": slot.cost,
            "dependent_id": slot.dependent_id,
            "name": slot.name,
            "horoscope_id": slot.horoscope_id
          });
          data.time_id += (data.time_id) ? "," + slot.time_id : slot.time_id;
        });
        var paymentForm;
        this.slotsAddService.saveOrder(data).subscribe(response => {
          if (data.paying_through == 'paypal') {
            window.location.href = response.link;
          } else {
            new Promise((resolve, reject) => {
              this.merchant_id = response.data.merchant_id;
              this.order_id = response.data.order_id;
              (<HTMLFormElement>document.getElementById("order_id")).value = this.order_id;
              (<HTMLFormElement>document.getElementById("merchant_id")).value = this.merchant_id;
              (<HTMLFormElement>document.getElementById("redirect_url")).value = this.apiUrl + "payments/completed/" + this.order_id;
              (<HTMLFormElement>document.getElementById("cancel_url")).value = this.apiUrl + "payments/cancelled/" + this.order_id;
              resolve();
            })
              .then((response) => {
                paymentForm = <HTMLFormElement>document.getElementById("paymentForm");
                paymentForm.submit();
              })
          }
        }, error => {
          this.paymentMessage = "Oops.. Something went wrong!! You can't book these slots.";
        })
      } else {
        this.paymentMessage = "This payment can't be done as the payment amount is invalid.";
      }
    } else {
      this.paymentMessage = "You have some missing fields. Please fill all fields and then try..";
    }
  }

  makeID(length) {
    var result = '';
    var characters = '0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  remove(i) {
    this.members.splice(i, 1);
  }
  
  onChangeCurrency(type) {
    this.payment_type = type;
  }

  deleteMember(i) {
    Swal.fire({
      title: 'Are you sure want to delete this Dependent?',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No, keep it',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      this.message = "Please wait... Dependent delete is in progress..."
      if (result.value) {
        this.slotsAddService.updateDependent(this.members[i].dependent_id, { is_deleted: true })
          .subscribe(
            (response) => {
              this.message = "";
              this.dependents_message = "";
              Swal.fire(
                'Deleted!',
                'Dependent Deleted successfully',
                'success'
              ).then((response) => {
                window.location.reload();
              })
            },
            error => {
              this.message = "";
              this.dependents_message = "";
            });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.message = "";
        this.dependents_message = "";
        Swal.fire(
          'Cancelled',
          'Dependent is not deleted',
          'error'
        )
      }
    })
    //this.members[i].is_deleted = true;
  }

  reloadWindow() {
    window.location.reload();
  }

}
