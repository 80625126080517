import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthorizedlistService {

  constructor(private http: HttpClient) { }

  getUserList() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `admins/list/2`, { headers: headers })
      .pipe(map((res: Response) => res));
  }

  deleteUser(Id: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.delete(`${environment.apiUrl}` + `admins/non-admin/` + Id, { headers: headers })
      .pipe(map((res: Response) => res));
  }
}
