import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthorizedaddService {

  constructor(private http: HttpClient) { }
  getPermissionList() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `admins/permissions/list`, { headers: headers })
      .pipe(map((res: Response) => res));
  }
  addUser(data) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.post(`${environment.apiUrl}` + `admins/non-admin`, data, { headers: headers })
      .pipe(map((res: Response) => res));
  }
  getData(id) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `admins/non-admin/`+id, { headers: headers })
    .pipe(map((response: any) => response));
  }
  update(id, data) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.put(`${environment.apiUrl}` + `admins/non-admin/`+id, data, { headers: headers })
    .pipe(map((response: any) => response));
  }
}
