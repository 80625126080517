import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ServicelistService {

  constructor(private http: HttpClient) { }

  getServiceList() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `service-type/list`, { headers: headers })
      .pipe(map((res: Response) => res));
  }

  deleteService(Id: any,serviceTypeId:any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.put(`${environment.apiUrl}` + `service-type/delete/${Id}/${serviceTypeId}`, {} ,{ headers: headers })
      .pipe(map((res: Response) => res));
  }


}
