import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';

import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class SlotsDetailsService {

  constructor(private http: HttpClient) { }
  getDailySlots(admin_id, date) {
    console.log(admin_id, date)
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `slots/`+admin_id+`/`+date, { headers: headers })
      .pipe(map((response: any) => response));
  }

  addDaySlots(admin_id, data) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.post(`${environment.apiUrl}` + `slots/`+admin_id+`/daily`, data, { headers: headers })
      .pipe(map((response: any) => response));
  }

  updateDaySlots(slot_id, data) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.put(`${environment.apiUrl}` + `slots/`+slot_id, data, { headers: headers })
      .pipe(map((response: any) => response));
  }

  getByWeek(admin_id, data) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.post(`${environment.apiUrl}` + `slots/weekly/`+admin_id, data, { headers: headers })
      .pipe(map((response: any) => response));
  }

  getByMonth(admin_id, month, year) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `slots/`+admin_id+`/monthly/`+year+`/`+month, { headers: headers })
      .pipe(map((response: any) => response));
  }

  getSlotsList() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `slots/list`, { headers: headers })
      .pipe(map((response: any) => response));
  }

}
