import { Routes, RouterModule } from '@angular/router';

import { AppLayoutComponent } from './dashboards/layouts/app-layout/app-layout.component';

import { AppointmentsListComponent } from './dashboards/shared/appointments/appointments-list/appointments-list.component';
import { AppointmentsDetailsComponent } from './dashboards/shared/appointments/appointments-details/appointments-details.component';
import { SlotsAddComponent } from './dashboards/shared/slots/slots-add/slots-add.component';
import { SlotsDetailsComponent } from './dashboards/shared/slots/slots-details/slots-details.component';
import { PaymentsDetailsComponent } from './dashboards/shared/payments/payments-details/payments-details.component';
import { PaymentsListComponent } from './dashboards/shared/payments/payments-list/payments-list.component';
import { AuthorizedUsersListComponent } from './dashboards/shared/authorized-user/authorized-users-list/authorized-users-list.component';
import { AuthorizedUsersAddComponent } from './dashboards/shared/authorized-user/authorized-users-add/authorized-users-add.component';
import { AuthorizedUsersDetailsComponent } from './dashboards/shared/authorized-user/authorized-users-details/authorized-users-details.component';
import { JoinComponent } from './dashboards/shared/video-calls/join/join.component';
import { LiveComponent } from './dashboards/shared/video-calls/live/live.component';
import { UserHomeComponent } from './dashboards/user/user-home/user-home.component';
import { UserProfileComponent } from './dashboards/user/user-profile/user-profile.component';
import { NotificationsListComponent } from './dashboards/shared/notifications/notifications-list/notifications-list.component';
import { CardInfoComponent } from './dashboards/shared/payments/card-info/card-info.component';
import { DependenciesListComponent } from './dashboards/shared/dependencies/dependencies-list/dependencies-list.component';
import { AuthGuardService } from './auth-guard.service';

import { MatchComparisonComponent } from './dashboards/shared/match-comparison/add/match-comparison.component';

const appRoutes: Routes = [

    {
        path: 'user',
        component: AppLayoutComponent,
        children: [
            { path: '', component: UserHomeComponent, canActivate: [AuthGuardService] },
            { path: 'match-comparison', component: MatchComparisonComponent, canActivate: [AuthGuardService] },
            { path: 'appointments/list', component: AppointmentsListComponent, canActivate: [AuthGuardService] },
            { path: 'appointments/list/:type', component: AppointmentsListComponent, canActivate: [AuthGuardService] },
            { path: 'appointments/details', component: AppointmentsDetailsComponent, canActivate: [AuthGuardService] },
            { path: 'appointments/book', component: SlotsAddComponent, canActivate: [AuthGuardService] },
            { path: 'appointments/details/:Id', component: AppointmentsDetailsComponent, canActivate: [AuthGuardService] },
            { path: 'appointments/status/:type', component: AppointmentsListComponent, canActivate: [AuthGuardService] },
            { path: 'video/:appointment_id/:meeting_id', component: JoinComponent, canActivate: [AuthGuardService] },
            { path: 'video/:appointment_id/:meeting_id/live', component: LiveComponent, canActivate: [AuthGuardService] },
            { path: 'payments/list', component: PaymentsListComponent, canActivate: [AuthGuardService] },
            { path: 'authorized-users/list', component: AuthorizedUsersListComponent, canActivate: [AuthGuardService] },
            { path: 'authorized-users/add', component: AuthorizedUsersAddComponent, canActivate: [AuthGuardService] },
            { path: 'authorized-users/details', component: AuthorizedUsersDetailsComponent, canActivate: [AuthGuardService] },
            { path: 'slots/list', component: SlotsDetailsComponent, canActivate: [AuthGuardService] },
            { path: 'slots/add', component: SlotsAddComponent, canActivate: [AuthGuardService] },
            { path: 'slots/details', component: SlotsDetailsComponent, canActivate: [AuthGuardService] },
            { path: 'profile', component: UserProfileComponent, canActivate: [AuthGuardService] },
            { path: 'notifications', component: NotificationsListComponent, canActivate: [AuthGuardService] },
            { path: 'payments/details', component: PaymentsDetailsComponent, canActivate: [AuthGuardService] },
            { path: 'payments/card-info', component: CardInfoComponent, canActivate: [AuthGuardService] },
            { path: 'dependents', component: DependenciesListComponent, canActivate: [AuthGuardService] },
        ]
    }
];

export const userRoutes = RouterModule.forRoot(appRoutes);

