import { Component, OnInit } from '@angular/core';
import { NotificatiosService } from './notificatios.service';
import { LoginComponent } from 'src/app/website/login/login.component';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.css']
})
export class NotificationsListComponent implements OnInit {
  responseMessage: any;
  nonotification = false;
  constructor(private apicall: NotificatiosService) { }

  ngOnInit() {
    this.apicall.getNotifications().subscribe(response => {
      this.responseMessage = response;
      this.responseMessage = this.responseMessage.data;
      console.log(this.responseMessage.length);

      if (this.responseMessage.length == '0') {
        this.nonotification = true;
        console.log(this.nonotification);
        
      }
    });
  }
}
