import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthorizedaddService } from './authorizedadd.service';

import { RegisterService } from '../../../../website/register/register.service';

import { patternValidator } from 'src/app/patternValidator';

import {PasswordRegExp} from '../../../../../environments/constants';

declare var $: any;

@Component({
  selector: 'app-authorized-users-add',
  templateUrl: './authorized-users-add.component.html',
  styleUrls: ['./authorized-users-add.component.css']
})
export class AuthorizedUsersAddComponent implements OnInit {
  addUserForm: FormGroup;
  responseMessage: any;
  errorMessage: any;
  submitted = false;
  permissionsList: any;
  params: any;

  countrylist: any;

  constructor(private formBuilder: FormBuilder, private apicall: AuthorizedaddService, private route: ActivatedRoute, private registerService: RegisterService) {
    this.route.params.subscribe(data => {
      this.params = data;
    });

    if (this.params.non_admin_id) {
      this.apicall.getData(this.params.non_admin_id).subscribe(response => {
        let data = response.data;
        this.createDefaultForm(data);
        this.permissionsList = data.permissions;
      });
    }
    this.registerService.getCountryList().subscribe(data => {
      this.countrylist = data;
      this.countrylist = this.countrylist.data;
    });
  }

  ngOnInit() {
    if (!this.params.non_admin_id) {
      this.apicall.getPermissionList().subscribe(response => {
        this.permissionsList = response;
        this.permissionsList = this.permissionsList.data;
      });
    }
    this.createDefaultForm({country_code: "+91"});
  }

  createDefaultForm(data) {
    this.addUserForm = this.formBuilder.group({
      first_name: [data.first_name ? data.first_name : null, [Validators.required, Validators.minLength(3)]],
      last_name: [data.last_name ? data.last_name : null, [Validators.required, Validators.minLength(3)]],
      country_code: [data.country_code ? data.country_code : "+91", [Validators.required]],
      primary_contact_number: [data.primary_contact_number ? data.primary_contact_number : null, [Validators.required, Validators.pattern('^[6-9]{1}[0-9]{9}$')]],
      email_id: [data.email_id ? data.email_id : null, [Validators.required, patternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      password: [data.password ? data.password : null, [Validators.required, Validators.pattern(PasswordRegExp)]],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.addUserForm.controls; }

  addNewuser() {
    this.submitted = true;
    if (this.addUserForm.invalid) {
      return;
    }
    let permissions = [];
    this.permissionsList.forEach((permission) => {
      permissions.push({
        "permission_id": permission.permission_id,
        "add": permission.add,
        "view": permission.view,
        "update": permission.update,
        "delete": permission.delete
      })
    })
    this.addUserForm.value.is_active = true;
    this.addUserForm.value.permissions = permissions;
    if (this.params.non_admin_id) {
      this.apicall.update(this.params.non_admin_id, this.addUserForm.value).subscribe(response => {
        this.responseMessage = response;
        $('#success-popup1').modal({ backdrop: 'static', keyboard: false })
        $('#success-popup1').modal('show');
      },
        error => {
          this.errorMessage = error.error.message;
        });
    } else {
      this.apicall.addUser(this.addUserForm.value).subscribe(response => {
        this.responseMessage = response;
        $('#success-popup').modal({ backdrop: 'static', keyboard: false })
        $('#success-popup').modal('show');
      },
        error => {
          this.errorMessage = error.error.message;
        });
    }
  }
}
