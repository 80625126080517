import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ForgotPasswordService } from './forgot-password.service';
declare var $: any;
import { patternValidator } from 'src/app/patternValidator';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotpasswordForm: FormGroup;
  submitted = false;
  showModal: boolean;
  responseMessage: any;
  errorMessage: any;
  buttonText = "Reset Password";
  user = "";
  navigator: any;


  constructor(private formBuilder: FormBuilder, private apicall: ForgotPasswordService) { }

  ngOnInit() {
    this.forgotpasswordForm = this.formBuilder.group({
      email_id: [null, [Validators.required, patternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
    });
    this.user = localStorage.getItem("userType");
    if (this.user == 'admins') {
      this.navigator = '/adminlogin'
    }
    else {
      this.navigator = '/login'
    }

  }

  // convenience getter for easy access to form fields
  get f() { return this.forgotpasswordForm.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.forgotpasswordForm.invalid) {
      return;
    } else {
      this.buttonText = "Loading...";
      const userType = localStorage.getItem('userType');
      this.apicall.forgotpassword(this.forgotpasswordForm.value, userType).subscribe(response => {
        this.responseMessage = response;
        this.submitted = false;
        if (this.responseMessage.success == true) {
          $('#success-popup').modal({ backdrop: 'static', keyboard: false })
          $('#success-popup').modal('show');
          this.buttonText = "Reset Password";
        } else {
          this.errorMessage = this.responseMessage.message;
          this.buttonText = "Reset Password";
        }
      },
        error => {
          this.errorMessage = error.error.message;
          this.buttonText = "Reset Password";
        });
    }
  }

  removeMessage() {
    if(this.errorMessage) {
      this.errorMessage = "";
    }
  }
}
