import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appointments-success',
  templateUrl: './appointments-success.component.html',
  styleUrls: ['./appointments-success.component.css']
})
export class AppointmentsSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
