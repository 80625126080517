import { Component, OnInit } from '@angular/core';
import { ServicelistService } from './servicelist.service';
declare var $: any;


@Component({
  selector: 'app-service-type-list',
  templateUrl: './service-type-list.component.html',
  styleUrls: ['./service-type-list.component.css']
})
export class ServiceTypeListComponent implements OnInit {
  settings = {};
  responseMessage: any;
  userType: any;
  userIndex: any;
  serviceId: any;
  serviceTypeId:any;
  serviceUserType:any;
  loading = true;
  currentPage: number;
  requestPage: number;
  userData: any;

  constructor(private _apicall:ServicelistService) { }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.userType = this.userData.user_type;

    this._apicall.getServiceList().subscribe(response => {
      this.responseMessage = response;
      this.responseMessage = this.responseMessage.data;
      this.loading = false;
    })
  }

  deleteservice(serviceId:any,serviceTypeId:any){
    $('#success-popup').modal({ backdrop: 'static', keyboard: false })
    $('#success-popup').modal('show');
    this.serviceId = serviceId;
    this.serviceTypeId = serviceTypeId;
  }

  confirmDelete() {
    this._apicall.deleteService(this.serviceId,this.serviceTypeId).subscribe(response => {
      $('#success-popup').modal({ backdrop: 'false', keyboard: false })
      $('#success-popup').modal('hide');
      this._apicall.getServiceList().subscribe(response => {
        this.responseMessage = response;
        this.responseMessage = this.responseMessage.data;
      });
    });
  }

  changePage(event) {
    this.currentPage = event;
    this.requestPage = this.currentPage - 1;
  }

}
