import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  constructor(private router: Router) {
  }

  canActivate() {
    let token = localStorage.getItem('token');
    let userData = localStorage.getItem('userData')
    if (token && userData)
      return true;
    else {
      this.router.navigateByUrl('/');
    }
  }
}
