import { Component, OnInit } from '@angular/core';
import {UserFaqsService} from './user-faqs.service';

@Component({
  selector: 'app-user-faqs',
  templateUrl: './user-faqs.component.html',
  styleUrls: ['./user-faqs.component.css']
})
export class UserFaqsComponent implements OnInit {

  faqs = [];

  activeFaq: any;
  searchTxt: any;

  constructor(private userFaqsService: UserFaqsService) { }

  ngOnInit() {

    this.userFaqsService.getList()
    .subscribe(response => {
      this.faqs = response.data;
    });
  }

  activate(index) {
    if(this.activeFaq != index)
      this.activeFaq = index;
    else
      this.activeFaq = -1;
  }

}
