import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DataTableModule } from 'angular5-data-table';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SchedulerModule } from 'angular-calendar-scheduler';
import { NgDatepickerModule } from 'ng2-datepicker';

import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
  LinkedinLoginProvider,
} from "angular-6-social-login";

// other imports
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxPaginationModule } from 'ngx-pagination'; // <-- import the module
import { Ng2SearchPipeModule } from 'ng2-search-filter'; //importing the module
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { AppComponent } from './app.component';
import { HomeComponent } from './website/home/home.component';
import { LoginComponent } from './website/login/login.component';
import { RegisterComponent } from './website/register/register.component';
import { ForgotPasswordComponent } from './website/forgot-password/forgot-password.component';
import { OtpComponent } from './website/otp/otp.component';
import { AppLayoutComponent } from './dashboards/layouts/app-layout/app-layout.component';
import { SidebarComponent } from './dashboards/layouts/sidebar/sidebar.component';
import { NavbarComponent } from './dashboards/layouts/navbar/navbar.component';

import { WebAppLayoutComponent } from './website/layout/web-app-layout/web-app-layout.component';
import { HeaderComponent } from './website/layout/header/header.component';
import { FooterComponent } from './website/layout/footer/footer.component';
import { AboutUsComponent } from './website/about-us/about-us.component';
import { ServicesComponent } from './website/services/services.component';
import { NewsComponent } from './website/news/news.component';
import { GalleryComponent } from './website/gallery/gallery.component';
import { ContactUsComponent } from './website/contact-us/contact-us.component';

import { AppointmentsListComponent } from './dashboards/shared/appointments/appointments-list/appointments-list.component';
import { AppointmentsSuccessComponent } from './dashboards/shared/appointments/appointments-success/appointments-success.component';
import { AppointmentsDetailsComponent } from './dashboards/shared/appointments/appointments-details/appointments-details.component';
import { SlotsAddComponent } from './dashboards/shared/slots/slots-add/slots-add.component';
import { SlotsDetailsComponent } from './dashboards/shared/slots/slots-details/slots-details.component';
import { PaymentsDetailsComponent } from './dashboards/shared/payments/payments-details/payments-details.component';
import { PaymentsListComponent } from './dashboards/shared/payments/payments-list/payments-list.component';
import { AuthorizedUsersListComponent } from './dashboards/shared/authorized-user/authorized-users-list/authorized-users-list.component';
import { AuthorizedUsersAddComponent } from './dashboards/shared/authorized-user/authorized-users-add/authorized-users-add.component';
import { AuthorizedUsersDetailsComponent } from './dashboards/shared/authorized-user/authorized-users-details/authorized-users-details.component';
import { JoinComponent } from './dashboards/shared/video-calls/join/join.component';
import { LiveComponent } from './dashboards/shared/video-calls/live/live.component';
import { AdminProfileComponent } from './dashboards/admin/admin-profile/admin-profile.component';
import { AdminHomeComponent } from './dashboards/admin/admin-home/admin-home.component';
import { UserHomeComponent } from './dashboards/user/user-home/user-home.component';
import { UserProfileComponent } from './dashboards/user/user-profile/user-profile.component';
import { NotificationsListComponent } from './dashboards/shared/notifications/notifications-list/notifications-list.component';
import { OtpLoginComponent } from './website/otp-login/otp-login.component';
import { CardInfoComponent } from './dashboards/shared/payments/card-info/card-info.component';
import { AdminLoginComponent } from './website/login-admin/login.component';
import { DependenciesListComponent } from './dashboards/shared/dependencies/dependencies-list/dependencies-list.component';
import { ClientsComponent } from './dashboards/shared/clients/clients.component';
import { ClientDetailsComponent } from './dashboards/shared/clients/client-details/client-details.component';
import { TooltipModule } from "ngx-tooltip";
import { HttpClientModule } from '@angular/common/http';
import { RestPasswordComponent } from './website/rest-password/rest-password.component';
import { SetPasswordComponent } from './website/set-password/set-password.component';

import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { UsersListComponent } from './dashboards/admin/users-list/users-list/users-list.component';
import { UserDetailsComponent } from './dashboards/admin/users-list/user-details/user-details.component';

import { MatchComparisonComponent } from './dashboards/shared/match-comparison/add/match-comparison.component';

import { websiteRoutes } from './app.website-routes';
import { routing } from './app.routes';
import { userRoutes } from './app.user-routes';
import { adminRoutes } from './app.admin-routes';
import { nonadminRoutes } from './app.non-admin-routes';
import { PrivacyPoliciesComponent } from './website/privacy-policies/privacy-policies.component';

import { ServiceTypeAddComponent } from './dashboards/shared/service-type/service-type-add/service-type-add.component';
import { ServiceTypeListComponent } from './dashboards/shared/service-type/service-type-list/service-type-list.component';
import { UserManualComponent } from './website/user-manual/user-manual.component';
import { UserFaqsComponent } from './website/user-faqs/user-faqs.component';

export function getAuthServiceConfigs() {
  let config = new AuthServiceConfig(
    [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider("473724393220827")
      },
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider("641880981164-jc00lkib0s41d8ktkiedep5pvd6cfp89.apps.googleusercontent.com")
      },
      {
        id: LinkedinLoginProvider.PROVIDER_ID,
        provider: new LinkedinLoginProvider("81iaiv79rn3bvh")
      },
    ]
  );
  return config;
}


@NgModule({
  imports: [
    BrowserAnimationsModule,
    SweetAlert2Module.forRoot(),
    BrowserModule,
    websiteRoutes,
    routing,
    userRoutes,
    adminRoutes,
    nonadminRoutes,
    TooltipModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataTableModule.forRoot(),
    NgxPaginationModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    NgxDaterangepickerMd.forRoot(),
    SchedulerModule.forRoot({ locale: 'en', headerDateFormat: 'daysRange' }),
    Ng2SearchPipeModule,
    NgDatepickerModule,
    SocialLoginModule,
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot()
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    AdminLoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    SetPasswordComponent,
    OtpComponent,
    SidebarComponent,
    NavbarComponent,
    AppLayoutComponent,
    WebAppLayoutComponent,
    HeaderComponent,
    FooterComponent,

    AboutUsComponent,
    ServicesComponent,
    NewsComponent,
    GalleryComponent,
    ContactUsComponent,

    AppointmentsListComponent,
    AppointmentsSuccessComponent,
    AppointmentsDetailsComponent,
    SlotsAddComponent,
    SlotsDetailsComponent,
    PaymentsDetailsComponent,
    PaymentsListComponent,
    AuthorizedUsersListComponent,
    AuthorizedUsersAddComponent,
    AuthorizedUsersDetailsComponent,
    JoinComponent,
    LiveComponent,
    AdminProfileComponent,
    AdminHomeComponent,
    UserHomeComponent,
    UserProfileComponent,
    NotificationsListComponent,
    MatchComparisonComponent,
    // CalendarComponent,
    OtpLoginComponent,
    CardInfoComponent,
    DependenciesListComponent,
    ClientsComponent,
    ClientDetailsComponent,
    RestPasswordComponent,
    PaymentStatusComponent,
    UsersListComponent,
    UserDetailsComponent,
    PrivacyPoliciesComponent,

    ServiceTypeAddComponent,
    ServiceTypeListComponent,
    UserManualComponent,
    UserFaqsComponent
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
