import { Component, OnInit } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ServiceaddService } from './serviceadd.service';

declare var $: any;


@Component({
  selector: 'app-service-type-add',
  templateUrl: './service-type-add.component.html',
  styleUrls: ['./service-type-add.component.css']
})
export class ServiceTypeAddComponent implements OnInit {

  addServiceForm: FormGroup;
  responseMessage: any;
  errorMessage: any;
  submitted = false;
  params: any;
  service = [
    // service_description:'',
  ]

  constructor(private formBuilder: FormBuilder, private apicall: ServiceaddService, private route: ActivatedRoute) {

    this.route.params.subscribe(data => {
      this.params = data;
      console.log("this.params", this.params)
    });

    if (this.params.service_type_id) {
      console.log("this.params.usr_srv_charge_id", this.params.usr_srv_charge_id)
      this.apicall.getData(this.params.service_type_id).subscribe(response => {
        console.log("get data response", response.data)
        let data = response.data[0]
        console.log("response", response.data)
        // this.service = response.data
        this.createDefaultForm(data);

      })
    }
  }

  ngOnInit() {

    // this.addServiceForm = this.formBuilder.group({
    //   description: [null, [Validators.required, Validators.minLength(3)]],
    //   user_type: [null, [Validators.required]],
    //   service_charge_amount: [null, [Validators.required]],
    //   service_charge_desc: [null, [Validators.required]],
    //   created_date: [null],
    // });
    this.createDefaultForm('');

  }

  createDefaultForm(data) {
    this.addServiceForm = this.formBuilder.group({
      service_description: [data.service_description ? data.service_description : null, [Validators.required, Validators.minLength(3)]],
      user_type: [data.user_type ? data.user_type : null, [Validators.required]],
      service_charge_amount: [data.service_charge_amount ? data.service_charge_amount : null, [Validators.required, Validators.pattern('^[0-9]+([,.][0-9]+)?$')]],
      service_charge_desc: [data.service_charge_desc ? data.service_charge_desc : null, [Validators.required]],
      created_date: [data.created_date ? data.created_date : null],
    });
  }
  get f() { return this.addServiceForm.controls; }

  addNew() {
    console.log("add new")
    this.submitted = true;
    if (this.addServiceForm.invalid) {
      return;
    }

    this.addServiceForm.value.is_active = true;
    if (this.params.service_type_id) {
      console.log("this.servic", this.service)
      this.apicall.update(this.params.service_type_id, this.addServiceForm.value).subscribe(response => {
        this.responseMessage = response;
        $('#success-popup1').modal({ backdrop: 'static', keyboard: false })
        $('#success-popup1').modal('show');
      },
        error => {
          this.errorMessage = error.error.message;
        }
      )
    } else {
      console.log("this.addServiceForm.value", this.addServiceForm.value)
      this.apicall.addService(this.addServiceForm.value).subscribe(response => {
        this.responseMessage = response;
        $('#success-popup').modal({ backdrop: 'static', keyboard: false })
        $('#success-popup').modal('show');
      },
        error => {
          this.errorMessage = error.error.message;
        });

    }

  }

}
