import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { connect, Room } from 'twilio-video';
import { HttpClient } from '@angular/common/http';

import { AppointmentdetailsService } from '../../appointments/appointments-details/appointmentdetails.service';
import { AppointmentlistService } from '../../appointments/appointments-list/appointments-list.service';
import { LiveService } from './live.service';
import * as moment from 'moment';

declare var window: any;

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.scss']
})
export class LiveComponent implements OnInit, OnDestroy {
  @ViewChild('video') video: ElementRef;
  @ViewChild('twilio') twilio: ElementRef;

  error: any;
  room: Room;
  conflict = false;
  startTime: any = '';

  params: any;
  userData: any;
  appointmentDetails: any;

  hours = 0;
  minutes = 0;
  seconds = 0;
  timerStarted = false;

  hide = {
    notes: false,
    prescription: false
  };

  mute = {
    audio: false,
    video: false
  };

  interval: any;

  localVideo = false;

  alertMessage = '';
  timerMessage = '';

  private stream: MediaStream = null;

  isParticipantEnabled = {
    video: true,
    audio: true
  };

  constructor(private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private liveApi: LiveService,
    public API: AppointmentdetailsService,
    public listService: AppointmentlistService) {
    this.params = route.snapshot.params;
    this.userData = JSON.parse(localStorage.getItem('userData'));
  }

  ngOnInit() {
    this.getAppointmentDetails();
  }

  ngOnDestroy(): void {
    if (this.room) {
      console.log('Room Disconnect Success');
      this.room.disconnect();
      this.stopLocalStream();
    }
  }

  getAppointmentDetails() {
    this.API.getAppointmentDetails(this.params.appointment_id).subscribe(response => {
      this.appointmentDetails = response.data;
      this.getMeetingToken();
    });
  }

  getMeetingToken() {
    const reqObject = {
      participantID: this.userData.user_type === 'user' ? this.userData.user_id : this.userData.admin_id,
      room: `room${this.params.meeting_id}`
    };
    this.liveApi.getMeetingToken(reqObject)
      .subscribe((resp) => {
        this.connectTOMeeting(resp);
      }, (error) => {
        console.log(error);
      });
  }

  connectTOMeeting(res) {
    const self = this;
    connect(res['token'], {
      name: 'room' + this.params.meeting_id, audio: true,
      video: { width: 640, height: 480 }
    })
      .then(room => {
        this.room = room;
        room.participants.forEach(self.participantConnected);
        room.on('participantConnected', self.participantConnected);
        room.on('participantDisconnected', self.participantDisconnected);
        room.once('disconnected', error => room.participants.forEach(self.participantDisconnected));
        this.initLocalVideo();
      })
      .catch(error => console.log(error.message));
  }

  participantConnected = (participant) => {
    console.log('Participant "%s" connected', participant.identity);
    const identity = parseInt(participant.identity, 10);
    const { user_type } = this.userData;
    const { user_id, admin_id } = this.appointmentDetails;

    this.startTime = `Started At ${moment().format('h:mm a')}`;

    if (!this.timerStarted && user_type === 'admin' && identity === user_id) {
      this.startTimer();
    } else if (!this.timerStarted && user_type === 'user' && identity === admin_id) {
      this.startTimer();
    }

    const div = document.createElement('div');
    div.id = participant.sid;

    participant.on('trackSubscribed', track => this.trackSubscribed(div, track));
    participant.on('trackUnsubscribed', this.trackUnsubscribed);
    participant.on('trackDisabled', this.participantTrackDisabled)
    participant.on('trackEnabled', this.participantTrackEnabled)

    participant.tracks.forEach(publication => {
      if (publication.isSubscribed) {
        this.trackSubscribed(div, publication.track);
      }
    });

    document.getElementById('twilio').appendChild(div);
  };

  participantTrackDisabled = (track) => {
    this.isParticipantEnabled[track.kind] = false
  }

  participantTrackEnabled = (track) => {
    this.isParticipantEnabled[track.kind] = true
  }

  participantDisconnected = (participant) => {
    console.log('Participant "%s" disconnected', participant.identity);
    document.getElementById(participant.sid).remove();
    // this.endCall();
  };

  trackSubscribed = (div, track) => {
    if (track && track.kind !== 'data') {
      this.isParticipantEnabled[track.kind] = track.isEnabled
      div.appendChild(track.attach());
    }
  };

  trackUnsubscribed = (track) => {
    if (track && track.kind !== 'data') {
      track.detach().forEach(element => element.remove());
    }
  };

  toggleAudio() {
    this.mute.audio = !this.mute.audio;

    this.room.localParticipant.audioTracks.forEach((audioTrack) => {
      if (this.mute.audio) {
        audioTrack.track.disable();
      } else {
        audioTrack.track.enable();
      }
    });
  }

  toggleVideo() {
    this.mute.video = !this.mute.video;

    this.room.localParticipant.videoTracks.forEach((videoTrack) => {
      if (this.mute.video) {
        videoTrack.track.disable();
      } else {
        videoTrack.track.enable();
      }
    });
  }

  handleEndCall() {
    this.endCall();
  }

  endCall() {
    this.stopLocalStream();
    if (this.room) {
      this.room.disconnect();
    }
    this.router.navigate([`/${this.userData.user_type}/appointments/list`], { replaceUrl: true });
  }

  startTimer() {
    this.timerStarted = true;
    this.interval = setInterval(() => {
      this.showTimer();
    }, 1000);
  }

  showTimer() {
    const duration = 15;
    this.seconds += 1;
    if (this.seconds === 60) {
      this.minutes += 1;
      this.seconds = 0;
    }
    if (this.minutes === 60) {
      this.hours += 1;
      this.minutes = 0;
    }

    if ((this.minutes === (duration - 2)) && (this.seconds === 0)) {
      this.alertMessage = 'Your call is going to be disconnected in next 2 mins..';
    }

    if (this.minutes !== duration) {
      this.timerMessage = 'Time: ' + ((this.hours < 10) ? '0' + this.hours : this.hours) + ':'
        + ((this.minutes < 10) ? '0' + this.minutes : this.minutes) + ':'
        + ((this.seconds < 10) ? '0' + this.seconds : this.seconds);
    }

    if (this.minutes === duration) {
      this.updateStatus();
      this.endCall();
    }

    if ((this.minutes === (duration - 2)) && (this.seconds === 0)) {
      this.API.alertUsers(this.appointmentDetails).subscribe(response => {
      });
    }
  }

  toggleLocalVideo() {
    this.localVideo = !this.localVideo;
  }

  initLocalVideo() {
    const localMediaContainer = document.getElementById('local-track');
    const localVideoTrack: any = Array.from(this.room.localParticipant.videoTracks.values())[0];
    localMediaContainer.appendChild(localVideoTrack.track.attach());
  }

  stopLocalStream() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  updateStatus() {
    this.listService.updateInvite(this.params.appointment_id, { status: 'joined' })
      .subscribe(response => {

      });
  }

  private getMediaStream(): Promise<MediaStream> {
    const video_constraints = { video: true };
    const _video = this.video.nativeElement;
    return new Promise<MediaStream>((resolve, reject) => {
      // (get the stream)
      return navigator.mediaDevices
        .getUserMedia(video_constraints)
        .then(stream => {
          (<any>window).stream = stream; // make variable available to browser console
          _video.srcObject = stream;
          // _video.src = window.URL.createObjectURL(stream);
          _video.onloadedmetadata = function (e: any) {
          };
          _video.play();
          return resolve(stream);
        })
        .catch(err => reject(err));
    });
  }
}
