import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LiveService {

  constructor(private http: HttpClient) {
  }

  getCountryList() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `masterdata/countries`, {headers: headers})
      .pipe(map(response => {
        return response;
      }));
  }


  /*getMeetingToken(params) {
    return this.http.get(`https://still-anchorage-49990.herokuapp.com/token?${params}`)
      .pipe(map((res: any) => res));
  }*/

  getMeetingToken(object) {
    let headers = new HttpHeaders();
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    headers = headers.set('enctype', 'multipart/form-data');
    return this.http.post(`${environment.apiUrl}appointments/join-appointment`, object, {headers: headers})
      .pipe(map((res: any) => res));
  }
}
