import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentlistService } from '../dashboards/shared/payments/payments-list/paymentlist.service';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.css']
})
export class PaymentStatusComponent implements OnInit {

  status: any;
  userData: any;
  userType: any;
  orderID: any;
  responseMessage: any;
  constructor(private route: ActivatedRoute, private apicall: PaymentlistService) {
    this.route.params.subscribe(data =>
      this.status = data.status
    );
    this.route.params.subscribe(data =>
      this.orderID = data.orderID
    );
    this.userData = JSON.parse(localStorage.getItem('userData'));
  }

  ngOnInit() {
    this.apicall.getpaymentlist().subscribe(response => {
      this.responseMessage = response;
      
    });
  }

}
