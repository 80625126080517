import { Routes, RouterModule } from '@angular/router';

import { WebAppLayoutComponent } from './website/layout/web-app-layout/web-app-layout.component';

import { AboutUsComponent } from './website/about-us/about-us.component';
import { ServicesComponent } from './website/services/services.component';
import { NewsComponent } from './website/news/news.component';
import { GalleryComponent } from './website/gallery/gallery.component';
import { ContactUsComponent } from './website/contact-us/contact-us.component';

import { HomeComponent } from './website/home/home.component';
import { UserFaqsComponent } from './website/user-faqs/user-faqs.component';

const appRoutes: Routes = [
    {
        path: '',
        component: WebAppLayoutComponent,
        children: [
            { path: '', component: HomeComponent },
            { path: 'about-us', component: AboutUsComponent },
            { path: 'services', component: ServicesComponent },
            { path: 'news', component: NewsComponent },
            { path: 'gallery', component: GalleryComponent },
            { path: 'contact-us', component: ContactUsComponent },
            { path: "faqs", component: UserFaqsComponent }

        ]
    }
];

export const websiteRoutes = RouterModule.forRoot(appRoutes);

