import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payments-details',
  templateUrl: './payments-details.component.html',
  styleUrls: ['./payments-details.component.css']
})
export class PaymentsDetailsComponent implements OnInit {

  userType = "";
  activeTab = "";
  constructor() { }

  ngOnInit() {
    let url = window.location.pathname.split("/");
    this.userType = url[1];
    this.activeTab = url[2];
  }
}