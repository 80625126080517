import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersListService {


  constructor(private http: HttpClient) { }

  getUsersList() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `appointments/users/list`, { headers: headers })
      .pipe(map((res: Response) => res));
  }

  uploadDoc(user_id, userType, document, other_document) {
    let user_type = (userType == 'Dependent')?'user_dependents':'users';
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.post(`${environment.apiUrl}` + `users/document/`+user_type+`/` + user_id, { documentData: document, otherDocumentData: other_document }, { headers: headers })
      .pipe(map((res: Response) => res));
  }

}
