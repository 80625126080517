import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppointmentdetailsService } from './appointmentdetails.service';

@Component({
  selector: 'app-appointments-details',
  templateUrl: './appointments-details.component.html',
  styleUrls: ['./appointments-details.component.css']
})
export class AppointmentsDetailsComponent implements OnInit {
  userType = "";
  activeTab = "";
  params: any;
  responseMessage: any;
  userData: any;

  comments = [];

  constructor(private route: ActivatedRoute, private apicall: AppointmentdetailsService) { }

  ngOnInit() {
    this.userType = localStorage.getItem('userType');
    if (this.userType == 'admins') {
      this.userType = 'admin';
    }
    if (this.userType == 'users') {
      this.userType = 'user';
    }
    this.route.params.subscribe(data =>
      this.params = data
    );
    this.apicall.getAppointmentDetails(this.params.Id).subscribe(response => {
      this.responseMessage = response;
      this.responseMessage = this.responseMessage.data;
      this.responseMessage.comments.forEach((comment) => {
        this.comments.push({
          status: comment.status,
          comment: comment.comment,
          commentedAt: comment.created_date
        })
      });
    });
  }
}