import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { patternValidator } from 'src/app/patternValidator';
import * as enLocale from 'date-fns/locale/en';
import { DatepickerOptions } from 'ng2-datepicker';
import { MatchComparisonService } from './match-comparison.service';

declare var $: any;

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

import * as moment from 'moment';

@Component({
  selector: 'app-match-comparison',
  templateUrl: './match-comparison.component.html',
  styleUrls: ['./match-comparison.component.css']
})

export class MatchComparisonComponent implements OnInit {
  userType = "";
  activeTab = "";
  userData: any;

  params: any;

  responseMessage: any;
  comparisonForm: FormGroup;
  submitted = false;
  date = new Date();
  profile = {
    name: '',
    mobile: '',
    first_name: '',
    last_name: '',
    country_code: '',
    primary_contact_number: ''
  }
  options: DatepickerOptions = {
    locale: enLocale,
    minYear: 1900,
    maxYear: this.date.getFullYear() + 1
  };

  order_id: any;
  merchant_id: any;
  apiUrl: any;
  websiteUrl: any;

  admins: any;
  selected_admin_id: any;
  male_document: any;
  female_document: any;
  payment_type: any;

  service_cost: any;

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private matchComparisonService: MatchComparisonService, private router: Router) {
    this.userType = localStorage.getItem('userType');
    this.userData = JSON.parse(localStorage.getItem('userData'));
    if (this.userType == 'admins') {
      this.userType = 'admin';
    }
    if (this.userType == 'users') {
      this.userType = 'user';
    }

    this.payment_type = 'inr';
    this.apiUrl = environment.apiUrl;
    this.websiteUrl = environment.websiteUrl;
    this.getAdmins();

    this.matchComparisonService.amount().subscribe(response => {
      if (response.data && response.data) {
        this.service_cost = response.data.service_cost;
      }
    });
  }

  onChangeCurrency(type) {
    this.payment_type = type;
  }

  getAdmins() {
    this.matchComparisonService.getAdmins().subscribe(response => {
      if (response.data && response.data) {
        this.admins = response.data;
      }
    });
  }

  payInDollars() {
    this.submitted = true;
    if (this.comparisonForm.invalid) {
      return;
    } else if (this.selected_admin_id) {
      let data = this.comparisonForm.value;
      data.user_id = this.userData.user_id;
      data.admin_id = this.selected_admin_id;
      data.order_for = "match-comparisons";
      data.male_document = this.male_document;
      data.female_document = this.female_document;
      this.matchComparisonService.payInDollars(data).subscribe(response => {
        window.location.href = response.link;
      });
    } else {
      Swal.fire({
        title: '<strong>Please select Admin</strong>',
        type: 'warning',
        html: 'You haven\'t selected any admin. Please select and proceed..'
      })
    }
  }

  ngOnInit() {
    this.matchComparisonService.getProfileData().subscribe(response => {
      this.profile = response.data;
      this.profile.name = this.profile.first_name + " " + this.profile.last_name;
      this.profile.mobile = this.profile.country_code + "" + this.profile.primary_contact_number;
    });
    this.comparisonForm = this.formBuilder.group({
      male_first_name: ["", [Validators.required, Validators.minLength(3), Validators.pattern('^[^0-9]{1}[a-zA-Z\-0-9]*$')]],
      male_last_name: ["", [Validators.required, Validators.minLength(3), Validators.pattern('^[^0-9]{1}[a-zA-Z\-0-9]*$')]],
      male_time_of_birth: ["", [Validators.required, Validators.minLength(3)]],
      male_date_of_birth: [new Date(), [Validators.required, Validators.minLength(3)]],
      male_place_of_birth: ["", [Validators.required, Validators.minLength(3)]],
      male_pob_nearest_city: ["", [Validators.required, Validators.minLength(3)]],
      male_document: ["", [Validators.minLength(3)]],
      female_first_name: ["", [Validators.required, Validators.minLength(3), Validators.pattern('^[^0-9]{1}[a-zA-Z\-0-9]*$')]],
      female_last_name: ["", [Validators.required, Validators.minLength(3), Validators.pattern('^[^0-9]{1}[a-zA-Z\-0-9]*$')]],
      female_time_of_birth: ["", [Validators.required, Validators.minLength(3)]],
      female_date_of_birth: [new Date(), [Validators.required, Validators.minLength(3)]],
      female_place_of_birth: ["", [Validators.required, Validators.minLength(3)]],
      female_pob_nearest_city: ["", [Validators.required, Validators.minLength(3)]],
      female_document: ["", [Validators.minLength(3)]],
      note: ["", [Validators.minLength(3)]]
    });
  }

  get f() { return this.comparisonForm.controls; }

  readMaleURL(event, contentIndex, optionIndex) {
    if (event && event.target && event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      let reader = new FileReader();
      reader.onload = e => {
        this.male_document = "" + reader.result;
      }

      reader.readAsDataURL(file);
    }
  }

  readFemaleURL(event, contentIndex, optionIndex) {
    if (event && event.target && event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      let reader = new FileReader();
      reader.onload = e => {
        this.female_document = "" + reader.result;
      }

      reader.readAsDataURL(file);
    }
  }

  confirmPayment(type) {

    this.submitted = true;
    if (this.comparisonForm.invalid) {
      return;
    } else if (this.selected_admin_id) {
      var paymentForm;
      let data = this.comparisonForm.value;
      data.user_id = this.userData.user_id;
      data.admin_id = this.selected_admin_id;
      data.order_for = "match-comparisons";
      data.amount = this.service_cost;
      data.paying_through = ((type == 'inr') ? 'ccavenue' : 'paypal')
      data.payment = {
        "currency": "inr",
        "status": "success",
        "amount": this.service_cost,
        "admin_id": this.selected_admin_id,
        "account_id": null,
        "user_id": this.userData.user_id,
        "card_id": null,
        "trancation_id": null,
        "slotsCount": 0
      }
      data.male_document = this.male_document;
      data.female_document = this.female_document;
      this.matchComparisonService.saveOrder(data).subscribe(response => {
        if (data.paying_through == 'paypal') {
          window.location.href = response.link;
        } else {
          new Promise((resolve, reject) => {
            this.merchant_id = response.data.merchant_id;
            this.order_id = response.data.order_id;
            (<HTMLFormElement>document.getElementById("order_id")).value = this.order_id;
            (<HTMLFormElement>document.getElementById("merchant_id")).value = this.merchant_id;
            (<HTMLFormElement>document.getElementById("redirect_url")).value = this.apiUrl + "match-comparisons/completed/" + this.order_id;
            (<HTMLFormElement>document.getElementById("cancel_url")).value = this.apiUrl + "match-comparisons/cancelled/" + this.order_id;
            resolve();
          })
            .then((response) => {
              paymentForm = <HTMLFormElement>document.getElementById("paymentForm");
              paymentForm.submit();
            });
        }
      });
    } else {
      Swal.fire({
        title: '<strong>Please select Admin</strong>',
        type: 'warning',
        html: 'You haven\'t selected any admin. Please select and proceed..'
      })
    }

  }

}
