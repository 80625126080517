import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdminserviceService } from './adminprofile.service';
declare var $: any;

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.css']
})
export class AdminProfileComponent implements OnInit {
  responseMessage: any;
  errorMessage: any;
  profileUpdateForm: FormGroup;
  userData: any;
  edit = false;
  countrylist: any;
  stateList: any;
  selectedCountry = '';
  selectedState = '';
  selectedCity = '';
  countryid: any;
  stateid: any;
  citylist: any;
  submitted: any;
  selectedCode = '+91';
  selectedCodeAlt = '+91';

  constructor(private formBuilder: FormBuilder, private apicall: AdminserviceService) { }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    console.log(this.userData);
    this.apicall.getAdminData().subscribe(response => {
      this.responseMessage = response.data;
      console.log(this.responseMessage);
    },
      error => {
        this.errorMessage = error.error.message;
      });
    this.getFromData({});
    this.apicall.getCountryList().subscribe(data => {
      this.countrylist = data;
      this.countrylist = this.countrylist.data;
    });
  }

  getFromData(data) {
    this.profileUpdateForm = this.formBuilder.group({
      first_name: [data.first_name ? data.first_name : "", [Validators.required, Validators.minLength(3), Validators.pattern('^[^0-9]{1}[a-zA-Z\-0-9]*$')]],
      last_name: [data.last_name ? data.last_name : "", [Validators.required, Validators.minLength(3), Validators.pattern('^[^0-9]{1}[a-zA-Z\-0-9]*$')]],
      primary_contact_number: [data.primary_contact_number ? data.primary_contact_number : "", [Validators.required, Validators.pattern('^[6-9]{1}[0-9]{9}$')]],
      alt_contact_number: [data.alt_contact_number ? data.alt_contact_number : '', [Validators.pattern('^[6-9]{1}[0-9]{9}$')]],
      primary_address_1: [data.primary_address_1 ? data.primary_address_1 : "", [Validators.required, Validators.minLength(3)]],
      primary_address_2: [data.primary_address_2 ? data.primary_address_2 : '', [Validators.minLength(3)]],
      primary_city: [data.primary_city ? data.primary_city : undefined, [Validators.required]],
      primary_state: [data.primary_state ? data.primary_state : undefined, [Validators.required]],
      primary_zip: [data.primary_zip ? data.primary_zip : "", [Validators.required, Validators.minLength(6)]],
      primary_country: [data.primary_country ? data.primary_country.toUpperCase() : undefined, [Validators.required]],
      profilepic: [data.profilepic ? data.profilepic : "", [Validators.minLength(3)]],
      country_code: ["", [Validators.required]],
      alternate_country_code: ["", [Validators.required]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.profileUpdateForm.controls; }

  onCountryChange(event, loadCities) {
    if (this.countrylist) {
      let index = this.countrylist.map(function (country) { return "" + country.countryname; }).indexOf("" + this.profileUpdateForm.value.primary_country);
      if (index >= 0) {
        this.countryid = this.countrylist[index].countryid;
        this.stateList = null;
        this.citylist = null;

      }
      this.apicall.getstateList(this.countryid).subscribe(data => {
        this.stateList = data;
        this.stateList = this.stateList.data;
        if (loadCities) {
          this.onStateChange(this.responseMessage)
        }
      });
    }
  }
  onStateChange(event) {
    let index = this.stateList.map(function (state) { return "" + state.statename; }).indexOf("" + this.profileUpdateForm.value.primary_state);
    if (index >= 0) {
      this.stateid = this.stateList[index].stateid;
      this.apicall.getcityList(this.countryid, this.stateid).subscribe(data => {
        this.citylist = data;
        this.citylist = this.citylist.data;
      });
    }
  }
  saveUser() {
    this.submitted = true;
    if (this.profileUpdateForm.invalid) {
      return;
    }
    this.apicall.updateUserData(this.profileUpdateForm.value).subscribe(response => {
      $('#success-popup').modal({ backdrop: 'static', keyboard: false })
      $('#success-popup').modal('show');
      if (response.success === true) {
        this.userData.first_name = this.profileUpdateForm.value.first_name;
        this.userData.last_name = this.profileUpdateForm.value.last_name;
        localStorage.setItem('userData', JSON.stringify(this.userData));
      }

    },
      error => {
        this.errorMessage = error.error.message;
      });
  }

  editAccount() {
    this.edit = true;
    this.getFromData(this.responseMessage);
    if (this.responseMessage.primary_country) {
      this.onCountryChange(this.responseMessage, true);
    }
  }
  reload() {
    $('#success-popup').modal('hide');
    window.location.reload();
  }
}