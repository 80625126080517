import { Component, OnInit } from '@angular/core';
import { permissions } from 'src/environments/constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {
    

  }

}
