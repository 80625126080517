import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserFaqsService {

  constructor(private http: HttpClient) { }

  getList() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.apiUrl}faqs/list`)
    .pipe(map((response: any) => response));
  }
}