import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OtploginService } from './otplogin.service';
declare var $: any;

@Component({
  selector: 'app-otp-login',
  templateUrl: './otp-login.component.html',
  styleUrls: ['./otp-login.component.css']
})
export class OtpLoginComponent implements OnInit {

  userType: any;
  otpForm: FormGroup;
  submitted = false;
  showModal: boolean;
  responseMessage: any;
  errorMessage: any;
  buttonText = "Submit";

  constructor(private router: Router, private formBuilder: FormBuilder, private apicall: OtploginService) { }

  ngOnInit() {
    let url = window.location.pathname.split("/");
    this.userType = url[1];
    this.userType = localStorage.getItem('userType');

    this.otpForm = this.formBuilder.group({
      otp: ["", [Validators.required, Validators.minLength(5)]],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.otpForm.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.otpForm.invalid) {
      return;
    } else {
      this.buttonText = "Loading...";
      this.apicall.otpValidate(this.otpForm.value, this.userType).subscribe(response => {
        this.responseMessage = response;
        this.submitted = false;
        localStorage.setItem('userData', JSON.stringify(this.responseMessage.data.userData));
        if (this.responseMessage.data.userData.user_type == 'admin') {
          this.router.navigate(['/admin']);
        } else if (this.responseMessage.data.userData.user_type == 'non-admin') {
          this.router.navigate(['/non-admin']);
        } else {
          this.router.navigate(['/user/appointments/book']);
        }
      },
        error => {
          this.errorMessage = error.error.message;
          this.buttonText = "Submit";
        });
    }
  }

  removeMessage() {
    if(this.errorMessage) {
      this.errorMessage = "";
    }
  }
}
