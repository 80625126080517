import { Component, OnInit } from '@angular/core';
import { DependentService } from './dependent.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dependencies-list',
  templateUrl: './dependencies-list.component.html',
  styleUrls: ['./dependencies-list.component.css']
})
export class DependenciesListComponent implements OnInit {
  responseMessage: any;
  noData: boolean;
  loading = true;
  userData: any;

  constructor(private router: Router, private apicall: DependentService) { }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    // if (this.userData.profile == false) {
    //   this.router.navigate(['/user/profile']);
    // }
    this.loading = true;
    this.apicall.getdependentlist().subscribe(response => {
      this.responseMessage = response;
      this.responseMessage = this.responseMessage.data;
      this.loading = false;
      if (this.responseMessage.length == 0) {
        this.noData = true;
      }
    });
  }
}
