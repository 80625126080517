import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ServiceaddService {

  constructor(private http: HttpClient) { }

  addService(data){
    console.log("service data",data)
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.post(`${environment.apiUrl}` + `service-type/add`, data, { headers: headers })
      .pipe(map((res: Response) => res));

  }

  getData(id){
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `service-type/`+id, { headers: headers })
    .pipe(map((response: any) => response));
  }

  update(id,data){
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.put(`${environment.apiUrl}` + `service-type/update/`+id, data, { headers: headers })
    .pipe(map((response: any) => response));
  }
}
