import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppointmentlistService {


  constructor(private http: HttpClient) { }

  getappointmentlist(type) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `appointments/filter/` + type, { headers: headers })
      .pipe(map((res: Response) => res));
  }

  updateInvite(Id: any, data: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.put(`${environment.apiUrl}` + `appointments/` + Id, data, { headers: headers })
      .pipe(map((res: Response) => res));
  }

  filterappointment(type: any, ) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.get(`${environment.apiUrl}` + `appointments/list/` + type, { headers: headers })
      .pipe(map((res: Response) => res));
  }

  filter(status: any, date: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-token-code', localStorage.getItem('token'));
    return this.http.post(`${environment.apiUrl}` + `appointments/filters`, { status, date }, { headers: headers })
      .pipe(map((res: Response) => res));
  }
}
