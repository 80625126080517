import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {RegisterService} from './register.service';
import {DatepickerOptions} from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';
import {patternValidator} from 'src/app/patternValidator';
import * as moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  showModal: boolean;
  responseMessage: any;
  errorMessage: any;
  buttonText = 'Save & Continue';
  userType = false;
  countrylist: any;
  selectedCode = '+91';
  user = '';
  dateselected = new Date();
  options: DatepickerOptions = {
    placeholder: 'Date of Birth*',
    locale: enLocale,
    minYear: 1900,
    maxYear: this.dateselected.getFullYear() + 1
  };
  bsValue = new Date();
  dateOptions = {
    dateInputFormat: 'DD-MM-YYYY',
    selectWeek: false
  };
  isOpen = false;
  showTimeOfBirth = false;

  merediam = 'AM';
  hours: any;
  minutes: any;
  tempHours: any;
  tempMinutes: any;
  showCustomDate: any;
  maxDate: any;

  constructor(private formBuilder: FormBuilder, private apicall: RegisterService, private router: Router) {
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  ngOnInit() {

    if (this.router.url === '/register') {
      this.userType = true;
    }

    this.maxDate = new Date().toISOString().split('T')[0];

    this.registerForm = this.formBuilder.group({
      first_name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(/^[A-Za-z_-][A-Za-z0-9 _-]*$/)]],
      last_name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern(/^[A-Za-z_-][A-Za-z0-9 _-]*$/)]],
      email_id: [null, [Validators.required, patternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      primary_contact_number: [null, [Validators.maxLength(16), Validators.pattern(/^([0|\+[0-9]{1,5})?([1-9][0-9]{9})$/)]],
      signup_from: 'manual',
      country_code: [''],
      date_of_birth: ['', [Validators.required, Validators.minLength(3)]],
      place_of_birth: ['', [Validators.required, Validators.minLength(3)]],
      time_of_birth: ['', [Validators.required, Validators.minLength(3)]],
      gender: ['', [Validators.required, Validators.minLength(4)]],
    });

    this.apicall.getCountryList().subscribe(data => {
      this.countrylist = data;
      this.countrylist = this.countrylist.data;
    });
    this.user = localStorage.getItem('userType');


    this.registerForm.get('date_of_birth').valueChanges.subscribe((e) => {
      const today: any = moment(moment().format('YYYY-MM-DD'));
      const selected: any = moment(e);
      const _diff = today.diff(selected, 'days');

      if (isNaN(_diff)) {
        this.registerForm.get('date_of_birth').setErrors({required: true});
      } else if (_diff < 0) {
        this.registerForm.get('date_of_birth').setErrors({invalid: true});
      }
    });


    this.registerForm.get('time_of_birth').valueChanges.subscribe((e) => {
      if (e) {
        this.checkFutureTime(this.registerForm.value.date_of_birth, e);
      }
    });

  }

  isTimeValid(event) {
    if (event) {
      this.registerForm.get('time_of_birth').setErrors(null);
    } else {
      this.registerForm.get('time_of_birth').setErrors({invalid: true});
    }
  }

  onChangeMobile() {
    if (this.registerForm.value.primary_contact_number) {
      let text = '';
      for (var i = 0; i < this.registerForm.value.primary_contact_number.length; i++) {
        let number = this.registerForm.value.primary_contact_number.charCodeAt(i);
        if ((number == 43) || ((number >= 48) && (number <= 57))) {
          text += this.registerForm.value.primary_contact_number[i];
        }
      }
      this.registerForm.get('primary_contact_number').setValue(text);
    }
  }

  checkFutureTime(date, time) {
    if (!date || !time) {
      return false;
    }
    const selected_date = moment(`${date} ${time}:59`);
    const now = moment();
    const _diff = selected_date.diff(now, 'minutes');
    if (_diff > 0) {
      this.registerForm.get('time_of_birth').setErrors({invalid: true});
    } else {
      this.registerForm.get('time_of_birth').setErrors(null);
    }
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.router.url === '/admin-register') {
      if (this.registerForm.invalid) {
        return;
      } else {
        this.registerForm.value.time_of_birth = new Date(this.registerForm.value.time_of_birth).getHours() + ':' + new Date(this.registerForm.value.time_of_birth).getMinutes();
        this.buttonText = 'Loading...';
        this.apicall.adminRegister(this.registerForm.value).subscribe(response => {
            localStorage.setItem('emailAddress', this.registerForm.value.email_id);
            localStorage.setItem('userType', 'admins');
            this.responseMessage = response;
            if (this.responseMessage.success == true) {
              $('#success-popup').modal({backdrop: 'static', keyboard: false});
              $('#success-popup').modal('show');
            } else {
              this.errorMessage = this.responseMessage.message;
              this.buttonText = 'Save & Continue';
            }
          },
          error => {
            this.errorMessage = error.error.message;
            this.buttonText = 'Save & Continue';
          });
      }
    } else {
      this.checkFutureTime(this.registerForm.value.date_of_birth, this.registerForm.value.time_of_birth);

      if (this.hours >= 0 && this.minutes >= 0) {
        this.registerForm.controls['time_of_birth'].setValue(this.hours + ':' + this.minutes);
      }

      if (this.registerForm.invalid) {
        return;
      } else {

        this.buttonText = 'Loading...';
        this.apicall.userRegister(this.registerForm.value).subscribe(response => {
            localStorage.setItem('emailAddress', this.registerForm.value.email_id);
            localStorage.setItem('userType', 'users');
            this.responseMessage = response;
            if (this.responseMessage.success == true) {
              $('#success-popup').modal({backdrop: 'static', keyboard: false});
              $('#success-popup').modal('show');
            } else {
              this.errorMessage = this.responseMessage.message;
              this.buttonText = 'Save & Continue';
            }
          },
          error => {
            this.errorMessage = error.error.message;
            this.buttonText = 'Save & Continue';
          });
      }
    }
  }

  onClickTime() {
    this.showTimeOfBirth = true;
    setTimeout(() => {
      const ele: any = document.querySelector('[type="time"]');
      ele.focus();
    });
  }

  hoursChange() {
    if (this.hours > 12) {
      this.merediam = 'PM';
      this.hours = this.hours % 12;
    } else {
      this.merediam = 'AM';
    }

    this.onHoursKeyUp();
  }

  onDateChange() {
    // let date = this.registerForm.value.date_of_birth+"";
    // if(date.length == 2) {
    //   date=date+"-";
    // }
    // if(date.length == 5)
    //   date+="-";

    // this.registerForm.controls['date_of_birth'].setValue(date);

    this.showCustomDate = true;
    $('#date_of_birth').focus();
    $('#date_of_birth').trigger('click');
  }

  onHoursKeyUp() {
    if ((this.hours >= 0) && (this.hours < 24)) {
      this.tempHours = this.hours;
    } else if (this.tempHours >= 0) {
      this.hours = this.tempHours;
    } else {
      this.hours = 0;
    }
    if (this.submitted) {
      this.checkValidation();
    }
  }

  minutesChange() {
    this.onMinutesKeyUp();
  }

  onMinutesKeyUp() {
    if ((this.minutes >= 0) && (this.minutes < 60)) {
      this.tempMinutes = this.minutes;
    } else if (this.tempMinutes >= 0) {
      this.minutes = this.tempMinutes;
    } else {
      this.minutes = 0;
    }
    if (this.submitted) {
      this.checkValidation();
    }
  }

  checkValidation() {
    if (this.hours >= 0 && this.minutes >= 0) {
      this.registerForm.controls['time_of_birth'].setValue(this.hours + ':' + this.minutes);
    }
  }

  merediamChange() {
    this.merediam = (this.merediam == 'AM') ? 'PM' : 'AM';
  }

  onChangeKeyPress() {
    if (this.errorMessage) {
      this.errorMessage = '';
    }
  }
}
