import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentdetailsService } from '../../appointments/appointments-details/appointmentdetails.service';

declare var io: any;
declare var conference: any;
declare var getUserMedia: any;
declare var getMediaElement: any;
declare var $: any;

import * as moment from 'moment';

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.css']
})

export class JoinComponent implements OnInit {
  userType = "";
  activeTab = "";
  userData: any;

  params : any;
  
  responseMessage: any;
  constructor(private route: ActivatedRoute, private apicall: AppointmentdetailsService, private router: Router) {
    this.userType = localStorage.getItem('userType');
    if (this.userType == 'admins') {
      this.userType = 'admin';
    }
    if (this.userType == 'users') {
      this.userType = 'user';
    }
    this.route.params.subscribe(data => {
      this.params = data;
    });
    this.route.params.subscribe(data =>
      this.params = data
    );
    this.apicall.getAppointmentDetails(this.params.appointment_id).subscribe(response => {
      this.responseMessage = response;
      this.responseMessage = this.responseMessage.data;
    });
  }

  ngOnDestroy() {

    window.location.href = this.router.routerState.snapshot.url;

  }

  ngOnInit() {

    var config = {
      openSocket: function (config) {
        var SIGNALING_SERVER = environment.socketUrl;

        config.channel = 12345;
        var sender = 12345;

        io.connect(SIGNALING_SERVER).emit('new-channel', {
          channel: config.channel,
          sender: sender
        });

        var socket = io.connect(SIGNALING_SERVER + config.channel);
        socket.channel = config.channel;
        socket.on('connect', function () {
          if (config.callback) config.callback(socket);
        });

        socket.send = function (message) {
          socket.emit('message', {
            sender: sender,
            data: message
          });
        };

        socket.on('message', config.onmessage);
      },
      onRemoteStream: function (media) {
        var mediaElement = getMediaElement(media.video, {
          width: (videosContainer.clientWidth / 2) - 50,
          buttons: ['mute-audio', 'mute-video', 'full-screen', 'volume-slider']
        });
        mediaElement.id = media.stream.streamid;
        videosContainer.appendChild(mediaElement);
      },
      onRemoteStreamEnded: function (stream, video) {
        if (video.parentNode && video.parentNode.parentNode && video.parentNode.parentNode.parentNode) {
          video.parentNode.parentNode.parentNode.removeChild(video.parentNode.parentNode);
        }
      },
      onRoomFound: function (room) {
        var alreadyExist = document.querySelector('button[data-broadcaster="' + room.broadcaster + '"]');
        if (alreadyExist) return;

        if (typeof roomsList === 'undefined') roomsList = document.body;

        var tr = document.createElement('tr');
        tr.innerHTML = '<button id="second-user" class="join">Join Meeting</button>';
        roomsList.appendChild(tr);

        var joinRoomButton = tr.querySelector('.join');
        joinRoomButton.setAttribute('data-broadcaster', room.broadcaster);
        joinRoomButton.setAttribute('data-roomToken', room.roomToken);

      },
      onRoomClosed: function (room) {
        var joinButton = document.querySelector('button[data-roomToken="' + room.roomToken + '"]');
        if (joinButton) {
          joinButton.parentNode.parentNode.parentNode.parentNode.removeChild(joinButton.parentNode.parentNode.parentNode);
        }
      },
      onReady: function () {
        console.log('now you can open or join rooms');
      }
    };

    function setupNewRoomButtonClickHandler() {
      captureUserMedia(function () {
        conferenceUI.createRoom({
          roomName: '12345'
        });
      }, function () {
      });
    }

    function captureUserMedia(callback, failure_callback) {
      var video = document.createElement('video');
      video.muted = true;
      video.volume = 0;

      try {
        video.setAttributeNode(document.createAttribute('autoplay'));
        video.setAttributeNode(document.createAttribute('playsinline'));
        video.setAttributeNode(document.createAttribute('controls'));
      } catch (e) {
        video.setAttribute('autoplay', 'true');
        video.setAttribute('playsinline', 'true');
        video.setAttribute('controls', 'true');
      }

      getUserMedia({
        video: video,
        onsuccess: function (stream) {
          var mediaElement = getMediaElement(video, {
            width: (videosContainer.clientWidth / 2) - 50,
            // buttons: ['mute-audio', 'mute-video', 'full-screen', 'volume-slider']
            buttons: []
          });
          mediaElement.toggle('mute-audio');
          videosContainer.appendChild(mediaElement);

          callback && callback();
        },
        onerror: function () {
          alert('unable to get access to your webcam');
          callback && callback();
        }
      });
    }

    var conferenceUI = conference(config);

    var videosContainer = document.getElementById('videos-container') || document.body;
    var btnSetupNewRoom = document.getElementById('setup-new-room');
    var roomsList = document.getElementById('rooms-list');

    if (btnSetupNewRoom) {
      btnSetupNewRoom.onclick = setupNewRoomButtonClickHandler;
    }

    setTimeout(function() {
      setupNewRoomButtonClickHandler();
    },1000)

  }

}
