export const permissions = [{
    permission_id: 1,
    name: "APPOINTMENTS",
    icon: "fa-calendar",
    url: "appointments/list",
    activeRoute: "appointments"
}, {
    permission_id: 2,
    name: "SLOTS",
    icon: "fa-calendar-check-o",
    url: "slots/list",
    activeRoute: "slots"
}, {
    permission_id: 3,
    name: "PAYMENTS",
    icon: "fa-tag",
    url: "payments/list",
    activeRoute: "payments"
},
{
    permission_id: 5,
    name: "USERS",
    icon: "fa-user",
    url: "users/list",
    activeRoute: "users-list"
},
{
    permission_id: 4,
    name: "AUTHORIZED USERS",
    icon: "fa-users",
    url: "authorized-users/list",
    activeRoute: "authorized-users"
},
{
    permission_id: 6,
    name: "SERVICES",
    icon: "fa-list",
    url: "service-type/list",
    activeRoute: "service-type"
}];

export const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const minutes = [{
    minute: "00",
    meridiem: "AM",
    days: []
}, {
    minute: "15",
    meridiem: "AM",
    days: []
}, {
    minute: "30",
    meridiem: "AM",
    days: []
}, {
    minute: "45",
    meridiem: "AM",
    days: []
}];

export const hours = [{
    hour: 7,
    meridiem: "AM",
    minutes: []
}, {
    hour: 8,
    meridiem: "AM",
    minutes: []
}, {
    hour: 9,
    meridiem: "AM",
    minutes: []
}, {
    hour: 20,
    meridiem: "PM",
    minutes: []
}, {
    hour: 21,
    meridiem: "PM",
    minutes: []
}];

export const PasswordRegExp = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&}#[~\]:';"<>,.\/+={`^()_-])[A-Za-z\d$@$!%*?&}#[~\]:';"<>,.\/+={`^()_-].{5,}/;